import { createBrowserHistory, } from 'history';

const history = createBrowserHistory({
  
});

history.listen((e) => {
  window.scroll(0, 0);
  // console.log(e)
  // window.location.href = e.pathname
});

export default history;