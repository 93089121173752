
import React, { useState } from 'react';
import { useCookies } from 'react-cookie'
import i18n from '../i18n';



function Cookies(){
    
    const [cookies, setCookie] = useCookies(['accept_cookie'])
    const [reject, setReject] = useState(false)
    function acceptCookies() {

        let expires = new Date()
        expires.setTime(expires.getTime() * 1000)
        setCookie('accept_cookie', 'true', { path: '/',  expires})

    }

    function rejectCookies() {
        setReject(true)
    }

    return (
        cookies.accept_cookie != 'true' &&
        <div className="cookies-txt" style={{display: `${reject ? 'none' : 'block'}`}}>
            <p>{i18n.t("cookies_prompt_text")}
              <div>
                <button className="btn" onClick={acceptCookies}>{i18n.t('cookies_prompt_accept')}</button>
                <button className="btn" onClick={rejectCookies}>{i18n.t('cookies_prompt_reject')}</button>
              </div>
            </p>
          </div>
        
      );
}
  
export default Cookies;