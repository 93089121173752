import './ProductAttributes.css';
import React from 'react';
import i18n from '../i18n';
import Select from 'react-select';

class ProductAttributes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attributes: {},
      filteredAttributes: {},
      selectedAttributes: {},
      uniqueVariant: false,
      currentAttribute: false,
    };

    this.renderAttributes = this.renderAttributes.bind(this);
    this.onAttributeSelect = this.onAttributeSelect.bind(this);
    this.filterAttributes = this.filterAttributes.bind(this);
  }

  componentDidMount() {
    this.renderAttributes();

    i18n.onLanguageChange(() => {
      this.forceUpdate();
      this.renderAttributes();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.variants !== this.props.data.variants) {
      this.renderAttributes();
    }
  }

  renderAttributes() {
    const groupedAttributes = {};

    if ('variants' in this.props.data) {
      const allAttributes = [];
      this.props.data.variants.forEach((variant) => {
        variant.attributes.forEach((attribute) => {
          if (allAttributes.findIndex((a) => a.value_id === attribute.value_id) === -1) {
            allAttributes.push(attribute);
          }
        });
      });

      allAttributes.forEach((attribute) => {
        if (!(attribute.attribute_category in groupedAttributes)) {
          groupedAttributes[attribute.attribute_category] = [];
          groupedAttributes[attribute.attribute_category].ref = React.createRef();
        }

        groupedAttributes[attribute.attribute_category].push({
          label: i18n.get_value(attribute),
          value: attribute.value_id,
        });
      });
    }
    this.setState({
      ...this.state,
      attributes: groupedAttributes,
      filteredAttributes: groupedAttributes,
    });
  }

  filterAttributes() {
    let filteredAttributes = {};
    let uniqueVariantId = true;
    if (Object.keys(this.state.selectedAttributes).length === 0) {
      filteredAttributes = { ...this.state.attributes };
    } else {
      // this.props.data.variants.forEach((variant) => {
      //   variant.attributes.forEach((attribute) => {
      //     if (allAttributes.findIndex((a) => a.value_id === attribute.value_id) === -1) {
      //       allAttributes.push(attribute);
      //     }
      //   });
      // });
      Object.keys(this.state.attributes).map((attributeName) => { //λουπα στις κατηγοριες
        filteredAttributes[attributeName] = filteredAttributes[attributeName] || []; //σετταρουμε αν δεν υπαρχει 
        const isSelectedAttribute = typeof this.state.selectedAttributes[attributeName] !== 'undefined' && this.state.selectedAttributes[attributeName] !== null; //κοιταμε αν η κατηγορια έχει selected τιμή
        if (isSelectedAttribute) { // αν εχει selected τιμη η κατηγορια εκτελεσε τον κωδικα
          const selectedAttributeValue = this.state.selectedAttributes[attributeName].value; //παρε την selected τιμη της κατηγοριας 
          this.props.data.variants.forEach((variant) => {
            const attributeExistsInVariantAttributes = variant.attributes.findIndex((a) => a.attribute_category === attributeName && a.value_id === selectedAttributeValue) > -1;
            if (attributeExistsInVariantAttributes) { 
              variant.attributes.forEach((attribute) => {
                if (typeof filteredAttributes[attribute.attribute_category] === 'undefined') {
                  filteredAttributes[attribute.attribute_category] = [];
                }
                const attributeAlreadyAdded = filteredAttributes[attribute.attribute_category].findIndex((o) => o.value === attribute.value_id) > -1;
                if (!attributeAlreadyAdded && this.state.currentAttribute != attribute.attribute_category) {
                  filteredAttributes[attribute.attribute_category].push({
                    label: i18n.get_value(attribute),
                    value: attribute.value_id,
                  });
                }
              });
            }
          });
        }
      });
      if(this.state.currentAttribute){
          let gotAttributes = false;
          Object.keys(this.state.attributes).map((attributeName) => { //λουπα στις κατηγοριες
            if(attributeName != this.state.currentAttribute){
              const isSelectedAttribute = typeof this.state.selectedAttributes[attributeName] !== 'undefined' && this.state.selectedAttributes[attributeName] !== null; //κοιταμε αν η κατηγορια έχει selected τιμή
              if (isSelectedAttribute) { // αν εχει selected τιμη η κατηγορια εκτελεσε τον κωδικα
                const selectedAttributeValue = this.state.selectedAttributes[attributeName].value; //παρε την selected τιμη της κατηγοριας 
                gotAttributes = true;
                this.props.data.variants.forEach((variant) => {
                  const attributeExistsInVariantAttributes = variant.attributes.findIndex((a) => a.attribute_category === attributeName && a.value_id === selectedAttributeValue) > -1;
                  if (attributeExistsInVariantAttributes) { 
                    variant.attributes.forEach((attribute) => {
                      if (typeof filteredAttributes[attribute.attribute_category] === 'undefined') {
                        filteredAttributes[attribute.attribute_category] = [];
                      }
                      const attributeAlreadyAdded = filteredAttributes[attribute.attribute_category].findIndex((o) => o.value === attribute.value_id) > -1;
                      if (!attributeAlreadyAdded) {
                        filteredAttributes[attribute.attribute_category].push({
                          label: i18n.get_value(attribute),
                          value: attribute.value_id,
                        });
                      }
                    });
                  }
                });
              }
            }
          });
          if(!gotAttributes){
            this.props.data.variants.forEach((variant) => {
              variant.attributes.forEach((attribute) => {
                if(attribute.attribute_category == this.state.currentAttribute){
                  if (typeof filteredAttributes[attribute.attribute_category] === 'undefined') {
                    filteredAttributes[attribute.attribute_category] = [];
                  }
                  const attributeAlreadyAdded = filteredAttributes[attribute.attribute_category].findIndex((o) => o.value === attribute.value_id) > -1;
                  if (!attributeAlreadyAdded) {
                    filteredAttributes[attribute.attribute_category].push({
                      label: i18n.get_value(attribute),
                      value: attribute.value_id,
                    });
                  }
                }
              });
            });
          }
          
      }
      // this.state.attributes.filter( (a) => {
        
      // })
      let shouldSkip = false;
      this.props.data.variants.forEach((variant) => {
        
        if(shouldSkip) return false;

        uniqueVariantId = variant;
        variant.attributes.forEach((attribute) => {
          const isSelectedAttribute = typeof this.state.selectedAttributes[attribute.attribute_category] !== 'undefined' 
                                      && this.state.selectedAttributes[attribute.attribute_category] !== null //κοιταμε αν η κατηγορια έχει selected τιμή
                                      && this.state.selectedAttributes[attribute.attribute_category].value == attribute.value_id;
            
          if (!isSelectedAttribute) {
            uniqueVariantId = false;
            return;
          }
        } );
        
        if(uniqueVariantId){
          shouldSkip = true;
        }
      } );
      if(uniqueVariantId){
        this.props.onVariantChange(uniqueVariantId);
        console.log("unique variant is ", uniqueVariantId)
      }
     
    }

    this.setState({
      ...this.state,
      filteredAttributes: filteredAttributes,
      uniqueVariant: uniqueVariantId
    });
  }

  onAttributeSelect(attribute, option) {
    const sa = {
      ...this.state.selectedAttributes,
    };
    sa[attribute] = option;
    console.log(attribute, option)
    this.setState({
      ...this.state,
      selectedAttributes: sa,
      currentAttribute: attribute
    });

    setTimeout(() => {
      this.filterAttributes();
    });
  }

  render() {
    return (
      <div className="ProductAttributes">
        {
          Object.keys(this.state.attributes).map((attributeName, i) => {
            const rand = Math.random() * 9999999
            return (
              <>
                <label htmlFor={`select-${i}-${rand}`}>{i18n.t(attributeName)}</label>
                <Select
                  inputId={`select-${i}-${rand}`}
                  key={i}
                  className="select"
                  options={this.state.filteredAttributes[attributeName]}
                  placeholder={`${i18n.t('choose')} ${i18n.t(attributeName)}`}                
                  value={this.state.selectedAttributes[attributeName]}
                  onChange={(selectedOption) => this.onAttributeSelect(attributeName, selectedOption)}
                  ref={this.state.attributes[attributeName].ref}
                  theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: '#000000',  // Placeholder color
                    },
                })}
                />
              </>
            );
          })
        }

        {/* <button
          className="btn btn-outline"
          onClick={() => {
            this.setState({
              ...this.state,
              selectedAttributes: {},
            });

            setTimeout(() => {
              this.filterAttributes();
            });
          }}
        >
          {i18n.t('clear')}
        </button> */}
      </div>
    );
  }
}

export default ProductAttributes;