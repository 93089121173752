import './ProductItem.css';
import React from 'react';
import i18n from '../i18n';
import {
  Link,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as faHeartFilled, faEye, faShoppingBasket, faTimes, faStar as faStarFilled, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import NumberPicker from '../number-picker/NumberPicker';
import basket from '../basket';
import favouritesHelper from '../favouritesHelper';
import ProductAvailability from '../product-availability/ProductAvailability';
import ProductAttributes from '../product-attributes/ProductAttributes';

class ProductItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveringFavouriteButton: false,
      showQuickView: false,
      showToast: false,
      showFavouriteToast: false,
      showErrorToast: false,
      thumbnail: '',
      count: 1,
      isFavourite: favouritesHelper.favourites.findIndex((i) => i.id === this.props.data.id) > -1,
      selectedVariant: false,
    };

    this.onButtonFavouriteMouseOver = this.onButtonFavouriteMouseOver.bind(this);
    this.onButtonFavouriteMouseLeave = this.onButtonFavouriteMouseLeave.bind(this);
    this.onButtonFavouriteClick = this.onButtonFavouriteClick.bind(this);
    this.add = this.add.bind(this);
    this.closeToast = this.closeToast.bind(this);
    this.openQuickView = this.openQuickView.bind(this);
    this.closeQuickView = this.closeQuickView.bind(this);
    this.onCountChange = this.onCountChange.bind(this);
    this.closeFavouriteToast = this.closeFavouriteToast.bind(this);
    this.closeErrorToast = this.closeErrorToast.bind(this);
    this.setNewVariantData = this.setNewVariantData.bind(this);
  }

  componentDidMount() {
    favouritesHelper.onChange(() => {
      this.setState({
        ...this.state,
        isFavourite: favouritesHelper.favourites.findIndex((i) => i.id === this.props.data.id) > -1,
      });
    });

    const image = new Image();
    image.onload = () => {
      this.setState({
        ...this.state,
        thumbnail: this.props.data.image,
      });
    };

    image.onerror = () => {
      this.setState({
        ...this.state,
        thumbnail: '/no-product-image-image.png',
      });
    };

    image.src = this.props.data.image;
  }

  onButtonFavouriteMouseOver() {
    this.setState({
      ...this.state,
      hoveringFavouriteButton: true,
    });
  }

  onButtonFavouriteMouseLeave() {
    this.setState({
      ...this.state,
      hoveringFavouriteButton: false,
    });
  }

  onButtonFavouriteClick() {
    const addedAsFavourite = favouritesHelper.add(this.props.data);

    this.setState({
      ...this.state,
      showFavouriteToast: addedAsFavourite,
      isFavourite: favouritesHelper.favourites.findIndex((i) => i.id === this.props.data.id) > -1,
    });

    setTimeout(() => {
      this.setState({
        ...this.state,
        showFavouriteToast: false,
      });

      this.forceUpdate();
    }, 5000);
  }

  add(e, count = 1) {
    if(this.props.data.variants.length == 0 || this.state.selectedVariant){
      this.setState({
        ...this.state,
        showToast: true,
      });
      
      basket.addProduct({
        ...this.props.data,
        thumbnail: this.props.data.image,
        selectedVariant: this.state.selectedVariant
      }, count);
  
      setTimeout(() => {
        this.setState({
          ...this.state,
          showToast: false,
        });
  
        this.forceUpdate();
      }, 5000);
    }else{
      this.setState({
        ...this.state,
        showErrorToast: true,
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          showErrorToast: false,
        });
  
        this.forceUpdate();
      }, 5000);
    }
    
  }

  closeToast() {
    this.setState({
      ...this.state,
      showToast: false,
    });
  }

  openQuickView() {
    this.setState({
      ...this.state,
      showQuickView: true,
    });
  }

  closeQuickView() {
    this.setState({
      ...this.state,
      showQuickView: false,
    });
  }

  onCountChange(value) {
    this.setState({
      ...this.state,
      count: value,
    });
  }

  closeFavouriteToast() {
    this.setState({
      ...this.state,
      showFavouriteToast: false,
    });
  }
  
  closeErrorToast() {
    this.setState({
      ...this.state,
      showErrorToast: false,
    });
  }

  setNewVariantData(v) {
    console.log(v)
    this.setState({
      ...this.state,
      selectedVariant: v,
    });
  }

  calculatePrice() {
    /*
    this.props.data.sale
                ? <span className="discount-price">{parseFloat(this.props.data.sale).toFixed(2)}&nbsp;€</span>
                : ''


    this.props.data.price || this.props.data.initial_price
    */
    let price = 0;
    if(this.state.selectedVariant && this.state.selectedVariant.price > 0){
      price = this.state.selectedVariant.price;
    }else{
      price = this.props.data.price || this.props.data.initial_price;
    }
    if(price == 0 && this.props.data.variants.findIndex((i) => i.price > 0) > -1){
      price = this.props.data.variants.reduce(function(prev, curr) {
        return prev.price < curr.price ? prev : curr;
      }).price;
      return "Από " + parseFloat(price).toFixed(2);
    }

    return parseFloat(price).toFixed(2);
  }
  calculateInitialPriceNum(){
    let price = this.props.data.price || this.props.data.initial_price;
    
    if(price == 0 && this.props.data.variants.findIndex((i) => i.price > 0) > -1){
      price = this.props.data.variants.reduce(function(prev, curr) {
        return prev.price < curr.price ? prev : curr;
      }).price;
      return parseFloat(price).toFixed(2);
    }

    return parseFloat(price).toFixed(2);
  }
  calculateInitialPrice(){
    let price = this.props.data.price || this.props.data.initial_price;
    
    if(price == 0 && this.props.data.variants.findIndex((i) => i.price > 0) > -1){
      price = this.props.data.variants.reduce(function(prev, curr) {
        return prev.price < curr.price ? prev : curr;
      }).price;
      return "Από " + parseFloat(price).toFixed(2);
    }

    return parseFloat(price).toFixed(2);
  }
  

  render() {
    return (
      <div className="product-item">
        <div
          className="image"
          style={{backgroundImage: `url('${this.state.thumbnail}')`}}
        >
          <Link
            to={`/products/${this.props.data.id}`}
            aria-label={this.props.data.name}
          ></Link>
        </div>
        {
          this.props.data.discount
            ? <div className="sale-tag">Sale</div>
            : ''
        }
        <div
          className="btn-favourite"
          onMouseOver={this.onButtonFavouriteMouseOver}
          onMouseLeave={this.onButtonFavouriteMouseLeave}
          onClick={this.onButtonFavouriteClick}
        >
          {
            this.state.hoveringFavouriteButton || this.state.isFavourite
              ? <FontAwesomeIcon
                  icon={faHeartFilled}
                  size="lg"
                />
              : <FontAwesomeIcon
                  icon={faHeart}
                  size="lg"
                />
          }
        </div>
        <div className="btn-quick-view">
          <FontAwesomeIcon
            icon={faEye}
            size="lg"
            onClick={this.openQuickView}
          />
        </div>
        <div className="info">
          {/* <div className="rating">
            {
              Array.from(Array(5)).map((n, i) => {
                if (i < this.props.data.rating) {
                  return (
                    <FontAwesomeIcon
                      icon={faStarFilled}
                      key={i}
                      style={{color: '#fea569', fontSize: '11px'}}
                    />
                  );
                } else {
                  return (
                    <FontAwesomeIcon
                      icon={faStar}
                      key={i}
                      style={{color: '#fea569', fontSize: '11px'}}
                    />
                  );
                }
              })
            }
          </div> */}
          <Link
            to={`/products/${this.props.data.id}`}
            className="name"
          >
            {/* {this.props.data.name} */}
            {i18n.get_name(this.props.data)}
          </Link>
          <div className="price">
            
            <span className={`${this.props.data.sale ? 'old-price' : 'initial-price'}`}>
              {this.calculateInitialPrice()}&nbsp;€
            </span>
          </div>
        </div>
        {
          this.props.data.availability.id < 3 && 
          <div
            className="btn-add-to-basket"
            // style={{backgroundColor: '#28a745'}}
            onClick={this.add}
          >
            <FontAwesomeIcon
              icon={faShoppingBasket}
              size="lg"
            />
            &nbsp;
            <span>{i18n.t('add_to_basket')}</span>
          </div>
        }
        

        <div className={`quick-view ${this.state.showQuickView ? 'open' : ''}`}>
          <div
            className="backdrop"
            onClick={this.closeQuickView}
          ></div>
          <div className="product">
            <div className="btn-close">
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                onClick={this.closeQuickView}
              />
            </div>
            <div
              className="image"
              style={{backgroundImage: `url('${this.state.thumbnail}')`}}
            ></div>
            <div className="info">
              <Link
                to={`/products/${this.props.data.id}`}
                className="name"
              >
                {/* {this.props.data.name} */}
                {i18n.get_name(this.props.data)}
              </Link>
              {/* <div className="rating">
                {
                  Array.from(Array(5)).map((n, i) => {
                    if (i < this.props.data.rating) {
                      return (
                        <FontAwesomeIcon
                          icon={faStarFilled}
                          key={i}
                          style={{color: '#fea569', fontSize: '11px'}}
                        />
                      );
                    } else {
                      return (
                        <FontAwesomeIcon
                          icon={faStar}
                          key={i}
                          style={{color: '#fea569', fontSize: '11px'}}
                        />
                      );
                    }
                  })
                }
              </div> */}
              
              <div className="price">
                <span className='initial-price'>{this.calculatePrice()}&nbsp;€</span>
                
              </div>
              
              {'availability' in this.props.data && <ProductAvailability data={this.props.data.availability} />}
              
              {/* <div className="description" dangerouslySetInnerHTML={{ __html: this.props.data.description }}></div> */}
              <div className="description" dangerouslySetInnerHTML={{ __html: i18n.get_desc(this.props.data) }}></div>
              
              <div className="mt-5">
                <ProductAttributes data={this.props.data} onVariantChange={ (v) => this.setNewVariantData(v)}/>
              </div>

              <div className="controls">
                <button
                  type="button"
                  className={`btn ${this.state.isFavourite ? '' : 'btn-outline'}`}
                  onClick={this.onButtonFavouriteClick}
                >
                  <FontAwesomeIcon
                    icon={faHeart}
                  />
                  &nbsp;
                  {this.state.isFavourite ? i18n.t('remove_from_favourite') : i18n.t('add_to_favourite')}
                </button>
                {
                  this.props.data.availability.id < 3 && 
                  <div className="basket-controls">
                    <NumberPicker
                      onChange={this.onCountChange}
                    />
                    
                    <button
                      type="button"
                      className="btn-add-to-basket btn ml-1"
                      // style={{backgroundColor: '#28a745'}}
                      onClick={(e) => this.add(e, this.state.count)}
                    >
                      <FontAwesomeIcon
                        icon={faShoppingBasket}
                      />
                      &nbsp;
                      {i18n.t('add_to_basket')}
                    </button>
                  </div>
                }
                
              </div>
            </div>
          </div>
        </div>

        <div className={`toast ${this.state.showToast ? 'open' : ''}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
            onClick={this.closeToast}
          />
          {i18n.t('product_added')}
          &nbsp;
          <Link to="/basket">{i18n.t('to_your_basket')}</Link>
        </div>

        <div className={`toast toast-error ${this.state.showErrorToast ? 'open' : ''}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
            onClick={this.closeErrorToast}
          />
          {i18n.t('product_added_error')}
        </div>

        <div className={`toast toast-favourite ${this.state.showFavouriteToast ? 'open' : ''}`}>
          <FontAwesomeIcon
            icon={faTimes}
            className="btn-close"
            onClick={this.closeFavouriteToast}
          />
          {i18n.t('product_added_as_favourite')}
        </div>
      </div>
    );
  }
}

export default ProductItem;