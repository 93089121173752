import './Footer.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons'
import storage from '../storage';
import logoImage from '../assets/img/logo-footer.png';
import Cookies from './Cookies';



class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showIartsLink: false,
    };

    this.elementRef = React.createRef();
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    let emailInputEl = this.elementRef.current.querySelector('.email-input');
    let inputEl = emailInputEl.querySelector('input');
    let placeholderEl = emailInputEl.querySelector('.placeholder');
    inputEl.addEventListener('focus', function() {
      placeholderEl.style.display = 'none';
    });
    inputEl.addEventListener('blur', function() {
      if (inputEl.value === '') {
        placeholderEl.style.display = 'block';
      }
    });
    placeholderEl.addEventListener('click', function() {
      inputEl.focus();
    });
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  acceptCookies() {

  }

  rejectCookies() {
    
  }

  render() {
    return (
      <div
        className="footer"
        ref={this.elementRef}
      >
        <div className="sections">
          <div className="about">
            <div className="logo">
              <Link to="/">
                <img src={logoImage} width="100" alt="Tsomakos Diamonds Logo" />
              </Link>
            </div>
            <div className="address">{i18n.get_address(storage.company)}, {i18n.get_city(storage.company)}, {storage.company.zip}</div>
            <div className="email">
              <span>{storage.company.email1}</span>
              &nbsp;
              <div className="social">
                {
                  storage.company.social_facebook !== '' ?
                  <a className="social-links" href={storage.company.social_facebook}><FontAwesomeIcon icon={faFacebook} /></a> :
                  ''
                }
                {
                  storage.company.social_instagram !== '' ?
                  <a className="social-links" href={storage.company.social_instagram}><FontAwesomeIcon icon={faInstagram} /></a> :
                  ''
                }
                {
                  storage.company.social_pinterest !== '' ?
                  <a className="social-links" href={storage.company.social_pinterest}><FontAwesomeIcon icon={faPinterest} /></a> :
                  ''
                }
              </div>
            </div>
            <div className="phone">{storage.company.phone1}, {storage.company.cell1}</div>
          </div>
          <div className="support">
            <h3>{i18n.t('support')}</h3>
            <Link to="/contact">{i18n.t('contact_us')}</Link>
            {/* <Link to="">{i18n.t('feedback')}</Link>
            <Link to="">{i18n.t('unsubscribe')}</Link> */}
          </div>
          <div className="policies">
            <h3>{i18n.t('policies')}</h3>
            <Link to="/terms/privacy_policy">{i18n.t('privacy_policy')}</Link>
            <Link to="/terms/terms_of_use">{i18n.t('terms_of_use')}</Link>
            {/* <Link to="">{i18n.t('shipping')}</Link> */}
            {/* <Link to="">{i18n.t('return_policy')}</Link> */}
          </div>
          <div className="subscribe">
            <h3>{i18n.t('stay_up_to_date')}</h3>
            <div className="email-input">
              <label htmlFor="subscribe-email-input">subsribe email input</label>
              <input
                id="subscribe-email-input"
                type="text"
              />
              <div className="placeholder">{i18n.t('your_email')}</div>
              <button
                type="button"
              >
                {i18n.t('subscribe')}
              </button>
            </div>
            <span className="info-text">{i18n.t('newsletter_info')}</span>
          </div>
        </div>
        <div className="bar">
          {
            !this.state.showIartsLink
              ? <span
                  onClick={() => this.setState({ ...this.state, showIartsLink: true })}
                  style={{cursor: 'pointer'}}
                >
                  © 2022 iarts.gr All Rights reserved
                </span>
              : ''
          }
          {
            this.state.showIartsLink
              ? <a href="https://iarts.gr">iarts.gr</a>
              : ''
          }
        </div>        
        <Cookies />
          
      </div>
    );
  }
}

export default Footer;