import './CheckoutPage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faShoppingBasket, faSquare, faCheckSquare, faCircle, faCheckCircle, faBoxOpen, faExclamation, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import basket from '../basket';
import Select from 'react-select';
import storage from '../storage';
import history from '../history';

class CheckoutPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      deliveryPointTypeOptions: [],
      deliveryPointType: 1,
      invoiceType: 'receipt',
      orderSent: false,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        buildingFloor: '',
        city: '',
        zip: '',
        notes: '',
        paymentMethod: 3,
        shippingMethod: 1,
        invoice: {
          company_name: '',
          vat: '',
          tax_office: '',
          profession: '',
          phone: '',
          address: '',
          city: '',
          zip: '',
        },
        order: null
      },
    };

    this.send = this.send.bind(this);
    this.onFormInputChange = this.onFormInputChange.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);
    this.onInvoiceFormInputChange = this.onInvoiceFormInputChange.bind(this);
  }

  componentDidMount() {
    document.title = i18n.t('checkout_page_title');
    if (storage.me.id) {
      this.state.form.firstname = storage.me.firstname;
      this.state.form.lastname = storage.me.lastname;
      this.state.form.email = storage.me.email;
      this.state.form.phone = storage.me.phone;
    }

    storage.onChange((property) => {
      if (property === 'me') {
        this.state.form.firstname = storage.me.firstname;
        this.state.form.lastname = storage.me.lastname;
        this.state.form.email = storage.me.email;
        this.state.form.phone = storage.me.phone;
      }
    });

    i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    basket.onChange(() => {
      this.setState({
        ...this.state,
        products: basket.products,
      });
      this.forceUpdate();
    });

    this.setState({
      ...this.state,
      products: basket.products,
    });

    request.get(`api/order-address-type`).then((response) => {
      if (response.data) {
        this.setState({
          ...this.state,
          deliveryPointTypeOptions: response.data.map((option) => {
            return {
              label: option.name,
              value: option.id,
            };
          }),
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    
    document.title = i18n.t('checkout_page_title');
    if (prevState.products.length !== this.state.products.length) {
      const getThumbnailsPromises = [];
      const p = this.state.products.slice();
      for (let i = 0; i < p.length; i++) {
        const thumbnail = new Image();
        getThumbnailsPromises.push(new Promise((resolve, reject) => {
          thumbnail.onload = () => {
            resolve(thumbnail);
          };

          thumbnail.onerror = ((i) => {
            return () => {
              p[i].thumbnail = '/no-product-image-image.png';
              resolve(thumbnail);
            };
          })(i);
        }));

        thumbnail.src = p[i].thumbnail;
      }

      Promise.all(getThumbnailsPromises).then((thumbnails) => {
        this.setState({
          ...this.state,
          products: p,
        });
      });
    }
  }

  send() {
    const clientData = {
      firstname: this.state.form.firstname,
      lastname: this.state.form.lastname,
      email: this.state.form.email,
      phone: this.state.form.phone,
      billingAddress: {
        address: this.state.form.address,
        floor: this.state.form.buildingFloor,
        city: this.state.form.city,
        zip: this.state.form.zip,
        type: this.state.deliveryPointType,
      },
      note: this.state.form.notes,
      paymentMethod: this.state.form.paymentMethod,
      shippingMethod: this.state.form.shippingMethod,
    };
    
    if (this.state.invoiceType === 'invoice') {
      clientData.invoice = this.state.form.invoice;
    }

    const productsData = this.state.products.map((p) => {
      return {
        id: p.id,
        quantity: p.quantity,
        //variant: [56, 59, 68][parseInt(Math.random() * 3)],
        variant: p.variant_id
      };
    });

    request.post(`api/add-order`, {
      client: clientData,
      products: productsData,
    }).then((response) => {
      if (!('status' in response.data)) {
        console.warn('Μη ολοκληρωμένη απάντηση από το server για την ολοκλήρωση της παραγγελίας');
        return;
      }

      if (response.data.status === 'ok') {
        if(response.data.payment_pending){
          history.push(`/checkout-payment/${response.data.order_id}`);
        }else{
          this.setState({
            ...this.state,
            orderSent: true,
            order: response.data.order_id,
            form: {
              firstname: '',
              lastname: '',
              email: '',
              phone: '',
              address: '',
              city: '',
              zip: '',
              notes: '',
              paymentMethod: 3,
              shippingMethod: 1,
              invoice: {
                company_name: '',
                vat: '',
                tax_office: '',
                profession: '',
                phone: '',
                address: '',
                city: '',
                zip: '',
              }
            },
          });
  
          basket.clear();
          window.scrollTo(0, 0);
        }
        
      }
    });
  }

  onFormInputChange(e, inputName) {
    const variableName = inputName.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [variableName]: e.target.value,
      },
    });
  }

  onInvoiceFormInputChange(e, inputName) {
    const variableName = inputName.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        invoice: {
          ...this.state.form.invoice,
          [variableName]: e.target.value,
        }
      }
    });
  }

  removeProduct(product) {
    basket.removeProduct(product.id, product.variant_id);
  }

  calculatePrice(product) {
    let price = 0;
    if(product.selectedVariant && product.selectedVariant.price > 0){
      price = product.selectedVariant.price;
    }else{
      price = product.price || product.sale;
    }
    if(price == 0 && product.variants.findIndex((i) => i.price > 0) > -1){
      price = product.variants.reduce(function(prev, curr) {
        return prev.price < curr.price ? prev : curr;
      }).price;
      return "Από " + parseFloat(price).toFixed(2);
    }

    return parseFloat(price).toFixed(2);
  }
  render() {
    const grandTotal = this.state.products.map((product) => {
      return (this.calculatePrice(product)) * product.quantity;
    }).reduce((p, c) => {
      return p + c;
    }, 0);
    const taxes = grandTotal * 0.24;
    const subtotal = grandTotal - taxes;

    return (
      <div className={`checkout-page ${this.state.products.length === 0 && localStorage.basket === '{}' ? 'empty-height' : ''}`}>
        {
          this.state.orderSent === false && this.state.products.length === 0 && localStorage.basket !== '{}'
            ? <div className="skeletons">
                <div className="skeletons__form"></div>
                <div className="skeletons__basket"></div>
              </div>
            : ''
        }
        {
          this.state.orderSent === false && this.state.products.length === 0 && localStorage.basket === '{}'
            ? <div className="d-column-center w-100 h-100">
                <FontAwesomeIcon icon={faShoppingBasket} size="3x" />
                <span className="mt-2">{i18n.t('empty_basket')}</span>
              </div>
            : ''
        }
        <div className={`shipping-details ${this.state.products.length === 0 || this.state.orderSent ? 'hidden' : ''}`}>
          <div className="header">
            {i18n.t('shipping_address')}
          </div>
          <div className="body">
            <div className="input">
              <label htmlFor="firstname">firstname</label>
              <span>{i18n.t('firstname')}</span>
              <input
                id="firstname"
                type="text"
                value={this.state.form.firstname}
                onChange={(e) => this.onFormInputChange(e, 'firstname')}
              ></input>
            </div>
            <div className="input">
              <label htmlFor="lastname">lastname</label>
              <span>{i18n.t('lastname')}</span>
              <input
                id="lastname"
                type="text"
                value={this.state.form.lastname}
                onChange={(e) => this.onFormInputChange(e, 'lastname')}
              ></input>
            </div>
            <div className="input">
              <label htmlFor="email">email</label>
              <span>{i18n.t('email')}</span>
              <input
                id="email"
                type="text"
                value={this.state.form.email}
                onChange={(e) => this.onFormInputChange(e, 'email')}
              ></input>
            </div>
            <div className="input">
              <label htmlFor="phone">phone</label>
              <span>{i18n.t('phone')}</span>
              <input
                id="phone"
                type="text"
                value={this.state.form.phone}
                onChange={(e) => this.onFormInputChange(e, 'phone')}
              ></input>
            </div>
            <div className="input">
              <label htmlFor="address">address</label>
              <span>{i18n.t('address')}</span>
              <input
                id="address"
                type="text"
                value={this.state.form.address}
                onChange={(e) => this.onFormInputChange(e, 'address')}
              ></input>
            </div>
            <div className="input">
              <label htmlFor="building-floor">building floor</label>
              <span>{i18n.t('building_floor')}</span>
              <input
                id="building-floor"
                type="text"
                value={this.state.form.buildingFloor}
                onChange={(e) => this.onFormInputChange(e, 'building-floor')}
              ></input>
            </div>
            <div className="input">
              <label htmlFor="city">city</label>
              <span>{i18n.t('city')}</span>
              <input
                id="city"
                type="text"
                value={this.state.form.city}
                onChange={(e) => this.onFormInputChange(e, 'city')}
              ></input>
            </div>
            <div className="input">
              <label htmlFor="zip">zip</label>
              <span>{i18n.t('zip')}</span>
              <input
                id="zip"
                type="text"
                value={this.state.form.zip}
                onChange={(e) => this.onFormInputChange(e, 'zip')}
              ></input>
            </div>
            <div className="input checkbox">
              <label htmlFor="delivery-point">{i18n.t('delivery_point_type')}</label>

              <select
                id="delivery-point"
                value={this.state.deliveryPointType}
                onChange={(e) => this.setState({ ...this.state, deliveryPointType: e.target.value, })}
              >
                {
                  this.state.deliveryPointTypeOptions.map((option) => 
                    <option value={option.value}>{option.label}</option>
                  )
                }
              </select>
              {/* <Select
                id="delivery-point"
                placeholder={i18n.t('choose')}
                options={this.state.deliveryPointTypeOptions}
                value={this.state.deliveryPointType}
                onChange={(selectedOption) => this.setState({ ...this.state, deliveryPointType: selectedOption })}
              /> */}
              
              {this.state.deliveryPointType !== null && this.state.deliveryPointType.value === '2' && <span className="label label--secondary mt-1 fs-xs"><FontAwesomeIcon icon={faExclamation} />&nbsp;{i18n.t('delivery_point_type_office_hours')}</span>}
            </div>
            <div className="input w-100">
              <label htmlFor="notes">notes</label>
              <span>{i18n.t('notes')}</span>
              <textarea
                id="notes"
                value={this.state.form.notes}
                rows="2"
                onChange={(e) => this.onFormInputChange(e, 'notes')}
              ></textarea>
            </div>
            <div className="input checkbox">
              <span>{i18n.t('invoice_type')}</span>
              <div>
                {
                  this.state.invoiceType === 'receipt'
                    ? <FontAwesomeIcon icon={faCheckCircle} />
                    : <FontAwesomeIcon
                        icon={faCircle}
                        onClick={() => this.setState({ ...this.state, invoiceType: 'receipt' })}
                      />
                }
                &nbsp;
                <span
                  onClick={() => this.setState({ ...this.state, invoiceType: 'receipt' })}
                >
                  {i18n.t('invoice_type_receipt')}
                </span>
              </div>
              <div>
                {
                  this.state.invoiceType === 'invoice'
                    ? <FontAwesomeIcon icon={faCheckCircle} />
                    : <FontAwesomeIcon
                        icon={faCircle}
                        onClick={() => this.setState({ ...this.state, invoiceType: 'invoice' })}
                      />
                }
                &nbsp;
                <span
                  onClick={() => this.setState({ ...this.state, invoiceType: 'invoice' })}
                >
                  {i18n.t('invoice_type_invoice')}
                </span>
              </div>
            </div>
          </div>
          {
            this.state.invoiceType === 'invoice'
              ? <React.Fragment>
                <div className="header">
                  {i18n.t('invoice_details')}
                </div>
                <div className="body">
                  <div className="input">
                    <label htmlFor="company_name">company_name</label>
                    <span>{i18n.t('company_name')}</span>
                    <input
                      id="company_name"
                      type="text"
                      value={this.state.form.invoice.company_name}
                      onChange={(e) => this.onInvoiceFormInputChange(e, 'company_name')}
                    ></input>
                  </div>
                  <div className="input">
                    <label htmlFor="vat">vat</label>
                    <span>{i18n.t('vat')}</span>
                    <input
                      id="vat"
                      type="text"
                      value={this.state.form.invoice.vat}
                      onChange={(e) => this.onInvoiceFormInputChange(e, 'vat')}
                    ></input>
                  </div>
                  <div className="input">
                    <label htmlFor="tax_office">tax_office</label>
                    <span>{i18n.t('tax_office')}</span>
                    <input
                      id="tax_office"
                      type="text"
                      value={this.state.form.invoice.tax_office}
                      onChange={(e) => this.onInvoiceFormInputChange(e, 'tax_office')}
                    ></input>
                  </div>
                  <div className="input">
                    <label htmlFor="profession">profession</label>
                    <span>{i18n.t('profession')}</span>
                    <input
                      id="profession"
                      type="text"
                      value={this.state.form.invoice.profession}
                      onChange={(e) => this.onInvoiceFormInputChange(e, 'profession')}
                    ></input>
                  </div>
                  <div className="input">
                    <label htmlFor="phone">phone</label>
                    <span>{i18n.t('phone')}</span>
                    <input
                      id="phone"
                      type="text"
                      value={this.state.form.invoice.phone}
                      onChange={(e) => this.onInvoiceFormInputChange(e, 'phone')}
                    ></input>
                  </div>
                  <div className="input">
                    <label htmlFor="address">address</label>
                    <span>{i18n.t('address')}</span>
                    <input
                      id="address"
                      type="text"
                      value={this.state.form.invoice.address}
                      onChange={(e) => this.onInvoiceFormInputChange(e, 'address')}
                    ></input>
                  </div>
                  <div className="input">
                    <label htmlFor="city">city</label>
                    <span>{i18n.t('city')}</span>
                    <input
                      id="city"
                      type="text"
                      value={this.state.form.invoice.city}
                      onChange={(e) => this.onInvoiceFormInputChange(e, 'city')}
                    ></input>
                  </div>
                  <div className="input">
                    <label htmlFor="zip">zip</label>
                    <span>{i18n.t('zip')}</span>
                    <input
                      id="zip"
                      type="text"
                      value={this.state.form.invoice.zip}
                      onChange={(e) => this.onInvoiceFormInputChange(e, 'zip')}
                    ></input>
                  </div>
                </div>
              </React.Fragment>
            : ''
          }
          <div className="body">
            <div className="input checkbox">
              <span>{i18n.t('payment_method')}</span>
              <div>
                {
                  this.state.form.paymentMethod === 3
                    ? <FontAwesomeIcon icon={faCheckSquare} />
                    : <FontAwesomeIcon
                        icon={faSquare}
                        onClick={() => this.setState({ ...this.state, form: { ...this.state.form, paymentMethod: 3 } })}
                      />
                }
                &nbsp;
                <span
                  onClick={() => this.setState({ ...this.state, form: { ...this.state.form, paymentMethod: 3 } })}
                >
                  {i18n.t('payment_method_online')}
                </span>
              </div>
              <div>
                {
                  this.state.form.paymentMethod === 1
                    ? <FontAwesomeIcon icon={faCheckSquare} />
                    : <FontAwesomeIcon
                        icon={faSquare}
                        onClick={() => this.setState({ ...this.state, form: { ...this.state.form, paymentMethod: 1 } })}
                      />
                }
                &nbsp;
                <span
                  onClick={() => this.setState({ ...this.state, form: { ...this.state.form, paymentMethod: 1 } })}
                >
                  {i18n.t('payment_method_deposit')}
                </span>
              </div>
              <div>
                {
                  this.state.form.paymentMethod === 4
                    ? <FontAwesomeIcon icon={faCheckSquare} />
                    : <FontAwesomeIcon
                        icon={faSquare}
                        onClick={() => this.setState({ ...this.state, form: { ...this.state.form, paymentMethod: 4 } })}
                      />
                }
                &nbsp;
                <span
                  onClick={() => this.setState({ ...this.state, form: { ...this.state.form, paymentMethod: 4 } })}
                >
                  {i18n.t('payment_method_on_delivery')}
                </span>
              </div>
            </div>
            <div className="input checkbox">
              <span>{i18n.t('deliver_method')}</span>
              <div>
                {
                  this.state.form.shippingMethod === 1
                    ? <FontAwesomeIcon icon={faCheckSquare} />
                    : <FontAwesomeIcon
                        icon={faSquare}
                        onClick={() => this.setState({ ...this.state, form: { ...this.state.form, shippingMethod: 1 } })}
                      />
                }
                &nbsp;
                <span
                  onClick={() => this.setState({ ...this.state, form: { ...this.state.form, shippingMethod: 1 } })}
                >
                  {i18n.t('deliver_method_courier')}
                </span>
              </div>
            </div>
          </div>
          <div className="footer">
            <button
              type="button"
              className={`btn btn-lg`}
              // disabled={this.state.form.lastname.trim().length === 0 || this.state.form.email.trim().length === 0 || this.state.form.phone.trim().length === 0}
              onClick={this.send}
            >
              <FontAwesomeIcon icon={faCheckDouble} />
              &nbsp;
              {i18n.t('make_order')}
            </button>
          </div>
        </div>
        <div className={`order-sent-message ${this.state.orderSent !== true || this.state.order === null ? 'hidden' : ''}`}>
          <FontAwesomeIcon icon={faCheckDouble} />
          <span
            style={{textShadow: '4px 0 0 #FAFAFA, -4px 0 0 #FAFAFA, 0 4px 0 #FAFAFA, 0 -4px 0 #FAFAFA, 1px 1px #FAFAFA, -1px -1px 0 #FAFAFA, 1px -1px 0 #FAFAFA, -1px 1px 0 #FAFAFA', color: '#000'}}
          >
            {i18n.t('order_sent_message')}
          </span>
          <div className="d-flex flex-direction-column align-items-center fs-xxl mt-5">
            <span style={{color: '#000'}} className="position-absolute fs-xs">{i18n.t('order_number')}</span>
            <span style={{color: '#000'}}>#{this.state.order ? this.state.order : '5129'}</span>
          </div>
        </div>
        <div className={`basket ${this.state.products.length === 0 ? 'hidden' : ''}`}>
          {
            this.state.products.map((product, i) => {
              return (
                <div
                  className="item"
                  key={i}
                >
                  <div
                    className="image"
                    style={{backgroundImage: `url('${product.thumbnail}')`}}
                  ></div>
                  <div className="details">
                    <div className="name"><Link to={`/products/${product.id}`}>{product.name}</Link></div>
                    <div className="mt-1 fs-sm">{i18n.t('quantity')} &times;{product.quantity}</div>
                    <div>{(product.quantity * (this.calculatePrice(product))).toFixed(2)}&nbsp;€</div>
                    {/* {
                      this.state.orderSent === false
                        ? <button
                            type="button"
                            className="btn btn-sm btn-outline"
                            onClick={() => this.removeProduct(product)}
                          >
                            {i18n.t('remove_product_from_basket')}
                          </button>
                        : ''
                    } */}
                  </div>
                </div>
              );
            })
          }
          <div className="subtotal">
            <span>{i18n.t('subtotal')}</span>
            <span>{subtotal.toFixed(2)}&nbsp;€</span>
          </div>
          <div className="taxes">
            <span>{i18n.t('taxes')}</span>
            <span>{taxes.toFixed(2)}&nbsp;€</span>
          </div>
          <div className="grandtotal">
            <span className="fw-bold">{i18n.t('grand_total')}</span>
            <span className="fw-bold">{grandTotal.toFixed(2)}&nbsp;€</span>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckoutPage;