import './ProductsPage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import CategoryItem from '../category-item/CategoryItem';
import request from '../request';
import ProductItem from '../product-item/ProductItem';
import ProductListItem from '../product-list-item/ProductListItem';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen, faAngleLeft, faAngleRight, faFilter, faSort, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import history from '../history';
import SkeletonProductItem from '../product-skeleton-item/SkeletonProductItem';
import storage from '../storage';
import InputRange from '../range-input/RangeInput';


class ProductsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      fetchingProducts: false,
      fetchIsDirty: false,
      categories: [],
      subcategories: [],
      currentCategory: [],
      categoriesExpanded: [],
      attributes: [],
      category: '',
      page: 1,
      pagesCount: 0,
      total: 0,
      displayedProducts: [],
      showFilters: false,
      sortMenuOpen: false,
      sort: {
        by: 'date',
        type: 'desc',
      },
      viewTypeMenuOpen: false,
      viewType: 'grid',
      filterAttributesValues: [],
      filters: {
        price: {
          from: 0,
          to: -1,
          max: -1,
        },
      },
    };

    this.onPriceRangeChangeTimeout = null;

    this.elementRef = React.createRef();

    this.sort = this.sort.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.chooseFilterAttributesValue = this.chooseFilterAttributesValue.bind(this);
    this.updatePriceRange = this.updatePriceRange.bind(this);
    this.onPriceFromChange = this.onPriceFromChange.bind(this);
    this.onPriceToChange = this.onPriceToChange.bind(this);
  }

  componentDidMount() {
    this.getProducts();
    document.title = i18n.t("products_page_title");
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    this.onWindowClick = (e) => {
      if (!this.elementRef.current.querySelector('.btn-sort').contains(e.target)) {
        this.setState({
          ...this.state,
          sortMenuOpen: false,
        });
      }
    }

    this.locationPathname = history.location.pathname;

    window.addEventListener('click', this.onWindowClick);

    this.setState({
      ...this.state,
      categories: storage.categories,
      fetchingProducts: true,
    });

    storage.onChange(() => {
      this.setState({
        ...this.state,
        categories: storage.categories,
      });
    });

    this.onHistoryChangeUnlisten = history.listen(() => {
      const locationClues = history.location.pathname.split('/');
      let categoryId;
      if (locationClues.length === 3) {
        categoryId = history.location.pathname.split('/').slice(-1)[0];
      } else if (locationClues.length === 4) {
        categoryId = history.location.pathname.split('/').slice(-2)[0];
      }

      if (this.state.category !== categoryId) {
        this.setState({
          ...this.state,
          products: [],
          attributes: [],
          page: 1,
        });

        setTimeout(() => {
          this.getProducts();
        });
      }

      if (categoryId === 'products') {
        this.getProducts();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    document.title = i18n.t("products_page_title");
    if (prevState.fetchingProducts !== this.state.fetchingProducts) {
      this.setState({
        ...this.state,
        fetchIsDirty: true,
      });
    }
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
    this.onHistoryChangeUnlisten();
    window.removeEventListener('click', this.onWindowClick);
  }

  getProducts(isLite = false) {
    if (!history.location.pathname.includes('categories') && !history.location.pathname.includes('search')) { // page changed FIXME
      return;
    }
    
    this.setState({
      ...this.state,
      fetchingProducts: true,
    });

    const locationClues = history.location.pathname.split('/');
    
    let categoryId, page;
    if (locationClues.length === 3) {
      categoryId = history.location.pathname.split('/').slice(-1)[0];
      page = this.state.page;
    } else if (locationClues.length === 4) {
      categoryId = history.location.pathname.split('/').slice(-2)[0];
      page = parseInt(history.location.pathname.split('/').slice(-1)[0]);
    }

    let filterAttributesValuesParam = '';
    if (this.state.filterAttributesValues.length > 0) {
      filterAttributesValuesParam = `&attributes=${this.state.filterAttributesValues.join(',')}`;
    }

    let getProductsURL;
    if (history.location.pathname.includes('categories')) {
      getProductsURL = `api/products/category/${categoryId}?page=${page}${filterAttributesValuesParam}&order_by=${this.state.sort.by}&order_type=${this.state.sort.type}${isLite ? '&lite=1' : ''}&price_from=${this.state.filters.price.from}&price_to=${this.state.filters.price.to}`;
    } else if (history.location.pathname.includes('/search/products')) {
      const searchQuery = history.location.pathname.split('/').slice(-1)[0];
      getProductsURL = `api/search/products?q=${searchQuery}`;
    }
    /////////
    request.get(`api/categories/${categoryId}`).then((response) => {
      this.state.currentCategory = response.data;
    });
    ///////
    request.get(getProductsURL).then((response) => {
      let attributes = this.state.attributes;
      if (response.data.data && response.data.data.attributes && (attributes.length === 0 || isLite === false)) {
        attributes = response.data.data.attributes;
        if (!('attributes' in response.data.data)) {
          attributes = [];
        }

      }
      
      if (response.data.data) {

        let priceTo = this.state.filters.price.to;
        if (priceTo === -1 || isLite === false) {
          priceTo = response.data.data.max_price;
        }

        let priceMax = this.state.filters.price.max;
        if (priceMax === -1 || isLite === false) {
          priceMax = response.data.data.max_price;
        }

        this.setState({
          ...this.state,
          page: page,
          category: categoryId,
          total: response.data.data.total,
          pagesCount: Math.ceil(response.data.data.total / 12),
          products: response.data.data.products.map((product) => {
            return {
              id: product.id,
              name: product.title,
              name_en: product.title_en,
              description: product.description,
              description_en: product.description_en,
              image: `https://manager.funky-office.gr/images/products/${product.thumbnail}` || product.thumbnail,
              rating: product.rating,
              price: product.price,
              availability: product.availability,
              variants: product.variants,
            };
          }),
          subcategories: 'categories' in response.data.data ? response.data.data.categories.map((subcategory) => {
            return {
              id: subcategory.id,
              name: subcategory.name,
              name_en: subcategory.name_en,
              image: subcategory.image,
              fallback_image: subcategory.fallback_image,
              productCount: subcategory.productsCount
            };
          }) : [],
          fetchingProducts: false,
          attributes: attributes,
          filters: {
            ...this.state.filters,
            price: {
              ...this.state.filters.price,
              to: priceTo,
              max: priceMax,
            },
          },
        });
      }
    });
  }

  sort(info) {
    const property = info.split('-')[0];
    const order = info.split('-')[1];

    this.setState({
      ...this.state,
      sortMenuOpen: false,
      sort: {
        by: property,
        type: order,
      },
    });

    setTimeout(() => {
      this.getProducts(true);
    });
  }

  chooseFilterAttributesValue(value) {
    const fav = this.state.filterAttributesValues.slice(0);
    const valueIndex = fav.indexOf(value);
    if (valueIndex > -1) {
      fav.splice(valueIndex, 1);
    } else {
      fav.push(value);
    }

    this.setState({
      ...this.state,
      filterAttributesValues: fav,
    });

    setTimeout(() => {
      this.getProducts(true);
    });
  }

  updatePriceRange(side, valuePerc) {
    const value = parseFloat(this.state.filters.price.max * (valuePerc / 100)).toFixed(2);

    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        price: {
          ...this.state.filters.price,
          [side]: value,
        },
      },
    });

    clearTimeout(this.onPriceRangeChangeTimeout);
    this.onPriceRangeChangeTimeout = setTimeout(() => {
      this.getProducts(true);
    }, 500);
  }

  onPriceFromChange(e) {
    let v = parseFloat(e.target.value);

    if (!isNaN(v) && v < this.state.filters.price.to) {
      this.setState({
        ...this.state,
        filters: {
          ...this.state.filters,
          price: {
            ...this.state.filters.price,
            from: v,
          },
        },
      });

      clearTimeout(this.onPriceRangeChangeTimeout);
      this.onPriceRangeChangeTimeout = setTimeout(() => {
        this.getProducts(true);
      }, 500);
    }
  }

  onPriceToChange(e) {
    let v = parseFloat(e.target.value);

    if (!isNaN(v) && v > this.state.filters.price.from) {
      this.setState({
        ...this.state,
        filters: {
          ...this.state.filters,
          price: {
            ...this.state.filters.price,
            to: v,
          },
        },
      });

      clearTimeout(this.onPriceRangeChangeTimeout);
      this.onPriceRangeChangeTimeout = setTimeout(() => {
        this.getProducts(true);
      }, 500);
    }
  }

  changePage(page) {
    this.setState({
      ...this.state,
      page: page,
    });

    let pathname = history.location.pathname;
    const clues = pathname.split('/');
    if (clues.length === 4) {
      clues[3] = page;
      pathname = clues.join('/');
    } else {
      pathname += '/' + page;
    }
    history.push(pathname);

    window.scroll(0,this.findPos(document.getElementById("products-wrapper")));


    setTimeout(() => {
      this.getProducts(true);
    });
  }

  findPos(obj) {
    var curtop = 0;
    if (obj.offsetParent) {
        do {
            curtop += obj.offsetTop;
        } while (obj = obj.offsetParent);
    return [curtop];
    }
  }

  render() {
    return (
      <div
        className="products-page"
        ref={this.elementRef}
      >
        
        {/* <div className='category-banner'>
          <img src={"https://manager.funky-office.gr/images/categories/" + this.state.currentCategory.image_banner} alt="Category Banner"/>
        </div> */}

        {
          Array.isArray(this.state.subcategories) && this.state.subcategories.length ? 
          (
            <div className="categories-page">
              <h3 className="title">{i18n.t('subcategories')}</h3>
              <div className={`list`}>
                {
                  this.state.subcategories.map((category, i) => {
                    return (
                      <CategoryItem
                        key={i}
                        data={category}
                      />
                    );
                  })
                }
              </div>
            </div>
          ) : ( '' )
        }

        <div className="options">
          <div className="d-flex">
            <div>
              <button
                type="button"
                className={`btn ${this.state.showFilters === false ? 'btn-outline' : ''} ml-1`}
                onClick={() => this.setState({ ...this.state, showFilters: !this.state.showFilters, })}
              >
                <FontAwesomeIcon icon={faFilter} />
                &nbsp;
                {i18n.t('filters')}
              </button>
            </div>
            <div className={`btn-change-view-type ml-1 ${this.state.viewTypeMenuOpen ? 'open' : ''}`}>
              <button
                type="button"
                className="btn btn-outline mr-1"
                onClick={() => this.setState({ ...this.state, viewTypeMenuOpen: !this.state.viewTypeMenuOpen })}
              >
                {i18n.t('products_view_type')}
              </button>
              <div className="menu">
                <span className={`${this.state.viewType === 'grid' ? 'selected' : ''}`} onClick={() => this.setState({ ...this.state, viewType: 'grid', viewTypeMenuOpen: false, showFilters: false, })}>{i18n.t('grid')}</span>
                <span className={`${this.state.viewType === 'list' ? 'selected' : ''}`} onClick={() => this.setState({ ...this.state, viewType: 'list', viewTypeMenuOpen: false, showFilters: false, })}>{i18n.t('list')}</span>
              </div>
            </div>
            <div className='have-found'>
              <span>
                {i18n.t('have_found')} { this.state.total } {i18n.t('have_found2')}
              </span>
            </div>
          </div>
          <div className={`btn-sort ${this.state.sortMenuOpen ? 'open' : ''}`}>
            <button
              type="button"
              className="btn btn-outline mr-1"
              onClick={() => this.setState({ ...this.state, sortMenuOpen: !this.state.sortMenuOpen })}
            >
              <FontAwesomeIcon icon={faSort} />
              &nbsp;
              {i18n.t('sort_by')}
            </button>
            <div className="menu">
              <span className={`${this.state.sort.by + '-' + this.state.sort.type === 'date-desc' ? 'selected' : ''}`} onClick={() => this.sort('date-desc')}>{i18n.t('date_high_to_low')}</span>
              <span className={`${this.state.sort.by + '-' + this.state.sort.type === 'price-asc' ? 'selected' : ''}`} onClick={() => this.sort('price-asc')}>{i18n.t('price_low_to_high')}</span>
              <span className={`${this.state.sort.by + '-' + this.state.sort.type === 'price-desc' ? 'selected' : ''}`} onClick={() => this.sort('price-desc')}>{i18n.t('price_high_to_low')}</span>
            </div>
          </div>
        </div>

        <div className={`d-flex ${this.state.viewType === 'grid' ? 'flex-direction-column' : 'flex-direction-row'}`}>
          <div className={`filters filters--${this.state.viewType} ${this.state.showFilters ? 'open' : ''} p-1`} style={{width: this.state.showFilters === false ? '0%' : (this.state.viewType === 'list' ? '33%' : '100%')}}>
            <div className="price">
              <div className="header">{i18n.t('price')}</div>
              <div>
                <div className="d-flex flex-row">
                  <div className="price-input">
                    <label htmlFor="priceFrom" className="d-none">Price from</label>
                    <input
                      id="priceFrom"
                      type="text"
                      value={this.state.filters.price.from}
                      onChange={this.onPriceFromChange}
                      onKeyDown={(e) => e.code === 'Enter' && this.onPriceFromChange(e)}
                    />
                    <span>€</span>
                  </div>
                  <div className="price-input">
                    <label htmlFor="priceTo" className="d-none">Price to</label>
                    <input
                      id="priceTo"
                      type="text"
                      value={this.state.filters.price.to}
                      onChange={this.onPriceToChange}
                      onKeyDown={(e) => e.code === 'Enter' && this.onPriceToChange(e)}
                    />
                    <span>€</span>
                  </div>
                </div>

                <InputRange
                  onChange={this.updatePriceRange}
                  from={(this.state.filters.price.from / this.state.filters.price.max) * 100}
                  to={(this.state.filters.price.to / this.state.filters.price.max) * 100}
                />
              </div>
            </div>
            <div className="attributes">
              {
                this.state.attributes.map((attribute, i) => {
                  return (
                    <div
                      className="attribute"
                      key={i}
                    >
                      <div className="name">{attribute.name}</div>
                      <div className="choices">
                        {
                          attribute.values.map((value, i) => {
                            return (
                              <div
                                key={i}
                                className={`choice ${this.state.filterAttributesValues.indexOf(value.id) > -1 ? 'selected' : ''}`}
                                onClick={() => this.chooseFilterAttributesValue(value.id)}
                              >
                                {value.name}
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>


          <h3 className="title title-padding" id="products-wrapper">{i18n.t('products_navigation')}</h3>
          <div className={`list mt-5`}>
            {
              this.state.fetchingProducts === false && this.state.products.map((product, i) => {
                let ProductComponent;
                if (this.state.viewType === 'grid') {
                  ProductComponent = <ProductItem
                    key={i}
                    data={product}
                  />;
                
                } else if (this.state.viewType === 'list') {
                  ProductComponent = <ProductListItem
                    key={i}
                    data={product}
                  />
                }

                return ProductComponent;
              })
            }
            {
              this.state.fetchingProducts && Array.from(Array(12)).map((n, i) => {
                return (
                  <SkeletonProductItem key={i} type={this.state.viewType}></SkeletonProductItem>
                );
              })
            }
            {
              this.state.fetchingProducts === false && this.state.products.length === 0 && this.state.fetchIsDirty && <div
                className="no-results-message"
              > 
                <FontAwesomeIcon icon={faBoxOpen} />
                <span>{i18n.t('no_products_found')}</span>
              </div>
            }
          </div>

        </div>
        {
          !this.state.page
            ? <div className="skeleton-pagination"><div></div><div></div><div></div></div>
            : ''
        }
        <div className={`pagination ${!this.state.page ? 'hidden' : ''}`}>
          <div onClick={() => this.changePage(Math.max(1, this.state.page - 1))}><FontAwesomeIcon icon={faAngleLeft} /></div>
          {
            this.state.page >= 3 && this.state.pagesCount >= 4
              ? <div onClick={() => this.changePage(1)}>1</div>
              : ''
          }
          {
            (this.state.page + 1 > this.state.pagesCount && this.state.pagesCount >= 3)
              ? <div onClick={() => this.changePage(this.state.page - 2)}>{this.state.page - 2}</div>
              : ''
          }
          {
            this.state.page > 1
              ? <div onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</div>
              : ''
          }
          {
            this.state.pagesCount > 0
              ? <div className="active">{this.state.page}</div>
              : ''
          }
          {
            this.state.page + 1 <= this.state.pagesCount
              ? <div onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 1}</div>
              : ''
          }
          {
            this.state.page <= 1 && this.state.pagesCount >= 3
              ? <div onClick={() => this.changePage(this.state.page + 2)}>{this.state.page + 2}</div>
              : ''
          }
          {
            this.state.page + 3 <= this.state.pagesCount
              ? <div onClick={() => this.changePage(this.state.pagesCount)}>{this.state.pagesCount}</div>
              : ''
          }
          <div onClick={() => this.changePage(Math.min(this.state.pagesCount, this.state.page + 1))}><FontAwesomeIcon icon={faAngleRight} /></div>
        </div>
      </div>
    );
  }
}

export default ProductsPage;