import './ProductPage.css';
import React, { useEffect, useState, useCallback, } from 'react';
import { Link, useParams, } from 'react-router-dom';
import request from '../request';
import config from '../config';
import NumberPicker from '../number-picker/NumberPicker';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faShoppingBasket, faTimes, faPaperPlane, faBasketballBall, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import favouritesHelper from '../favouritesHelper';
import history from '../history';
import ProductAvailaibility from '../product-availability/ProductAvailability';
import basket from '../basket';
import ProductAttributes from '../product-attributes/ProductAttributes';

function ProductPage(props) {
  const params = useParams();
  
  const [fetchingProduct, setFetchingProduct] = useState(false);
  const [product, setProduct] = useState({
    availability: {},
  });
  const [selectedVariant, setSelectedVariant] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [count, setCount] = useState(1);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const fetchProduct = () => {
    let resolveFn;
    const promise = new Promise((resolve, reject) => resolveFn = resolve);

    request.get(`api/product/${params.productId}`).then((response) => {
      console.log(i18n.get_title(response.data));
      let productUpdated = {
        id: response.data.id,
        // name: response.data.title,
        name: i18n.get_title(response.data),
        thumbnail: response.data.thumbnail,
        code: response.data.code,
        // miniDescription: response.data.mini_description,
        // description: response.data.description,
        miniDescription: i18n.get_desc(response.data),
        description: i18n.get_mini_desc(response.data),
        image: response.data.thumbnail,
        rating: response.data.rating,
        price: response.data.initial_price,
        discountPrice: response.data.price,
        discount: response.data.discount,
        availability: response.data.availability,
        variants: response.data.variants,
        availability_name: response.data.availability_name,
        availability_name_en: response.data.availability_name_en,
      };
      const image = new Image();
      image.onload = () => {
        productUpdated = {
          ...productUpdated,
          thumbnail: response.data.thumbnail
        }
      };

      image.onerror = () => {
        productUpdated = {
          ...productUpdated,
          thumbnail: '/no-product-image-image.png'
        }
      };
      document.title = `${productUpdated.name} - ${config.eshop_title}`;
      // document.title = `${productUpdated.name_en} - ${config.eshop_title}`;

      setProduct(productUpdated);
      setFetchingProduct(false);

      let lastSeenProducts = localStorage.getItem('last-seen-products');
      if (lastSeenProducts) {
        lastSeenProducts = JSON.parse(lastSeenProducts);
      } else {
        lastSeenProducts = [];
      }

      if (lastSeenProducts.indexOf(params.productId) === -1) {
        lastSeenProducts.push(params.productId);
        if (lastSeenProducts.length > 8) {
          lastSeenProducts.shift();
        } 
      }
      
      localStorage.setItem('last-seen-products', JSON.stringify(lastSeenProducts));

      resolveFn(productUpdated);
    });

    return promise;
  }

  useEffect(() => {
    setFetchingProduct(true);

    fetchProduct();  

    const unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      forceUpdate();
      fetchProduct();  
    });

    return () => {
      unlistenOnLanguageChange();
    };
  }, []);

  function setNewVariantData(v) {
    setSelectedVariant(v);
  }
  // useEffect(() => {
  //   fetchProduct().then((productUpdated) => {
  //     const thumbnail = new Image();

  //     thumbnail.onerror = () => {
  //       let p = productUpdated;
  //       p.thumbnail = '/no-product-image-image.png';
  //       setProduct(p);
  //       forceUpdate();
  //     };

  //     thumbnail.src = productUpdated.thumbnail;
  //   });
  // }, [params.productId]);
  function calculatePrice() {
    let price = 0;
    if(selectedVariant && selectedVariant.price > 0){
      price = selectedVariant.price;
    }else{
      price = product.discountPrice || product.PRICE;
    }
    if(price == 0 && product.variants.findIndex((i) => i.price > 0) > -1){
      price = product.variants.reduce(function(prev, curr) {
        return prev.price < curr.price ? prev : curr;
      }).price;
      return "Από " + parseFloat(price).toFixed(2);
    }

    return parseFloat(price).toFixed(2);
  }
  function calculateInitialPrice(){
    let price = product.price || product.discountPrice;
    
    if(price == 0 && product.variants.findIndex((i) => i.price > 0) > -1){
      price = product.variants.reduce(function(prev, curr) {
        return prev.price < curr.price ? prev : curr;
      }).price;
      return "Από " + parseFloat(price).toFixed(2);
    }

    return parseFloat(price).toFixed(2);
  }
  function onCountChange(value) {
    setCount(value);
  }

  function closeToast() {
    setShowToast(false);
  }

  function addToBasket() {
    if(product.variants.length == 0 || selectedVariant){
      
      
      basket.addProduct({
        ...product,
        thumbnail: product.image,
        selectedVariant: selectedVariant
      }, count);
  
      setShowToast(true);
    }else{
     
    }
    // basket.addProduct(product, count);
    // setShowToast(true);
  }

  function addToFavourite() {
    favouritesHelper.add(product);
  }

  return (
    <div className="product-page">
      <div className={`skeleton ${fetchingProduct === false ? 'hidden' : ''}`}>
        <div className="skeleton-image"></div>
        <div className="skeleton-details"></div>
      </div>
      <div className={`basic-info ${fetchingProduct ? 'hidden' : ''}`}>
        <div className="image">
          <img src={`https://manager.funky-office.gr/images/products/${product.thumbnail}`} alt={product.name} 
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="/no-product-image-image.png";
          }}
          />
        </div>
        <div className="details">
          <span className="code">#{product.code}</span>
          <span className="name">{product.name}</span>
          <div className="mini-description" dangerouslySetInnerHTML={{ __html: product.miniDescription }}></div>
          <div className="price">
            {
              // product.discount ?
              // <span className="discount-value">{parseFloat(product.discount.value * 100).toFixed(0)}% {i18n.t("discount")}</span> :
              // ''
            }
            <span className="current-price">{calculatePrice()}&nbsp;€</span>
          </div>
          
          {product.availability && <ProductAvailaibility data={product.availability} />}
          
          <div className="mt-5">
            <ProductAttributes data={product} onVariantChange={ (v) => setNewVariantData(v)}/>
          </div>

          <div className="controls">
            {
              product.availability.id < 3 && 
              <>
                <NumberPicker
                  onChange={onCountChange}
                />

                <button
                  type="button"
                  className="btn btn-lg"
                  // style={{backgroundColor: '#28a745'}}
                  onClick={addToBasket}
                >
                  <FontAwesomeIcon icon={faShoppingBasket} />
                  &nbsp;
                  {i18n.t('add_to_basket')}
                </button>
              </>
            }
            

            <button
              type="button"
              className="btn btn-lg btn-outline"
              onClick={addToFavourite}
            >
              <FontAwesomeIcon icon={faHeart} />
              <span className="d-none">Favourite</span>
            </button>
          </div>
        </div>
      </div>
      <div className={`extra-info ${fetchingProduct ? 'hidden' : ''}`}>
        {
          !fetchingProduct && product.description && product.description.length > 0 && <React.Fragment>
            <div className="header">{i18n.t('description')}</div>
            <div className="description" dangerouslySetInnerHTML={{ __html: product.description }}></div>   
          </React.Fragment>
        }
      </div>
      <div className={`toast ${showToast ? 'open' : ''}`}>
        <FontAwesomeIcon
          icon={faTimes}
          className="btn-close"
          onClick={closeToast}
        />
        {i18n.t('product_added')}
        &nbsp;
        <Link to="/basket">{i18n.t('to_your_basket')}</Link>
      </div>
    </div>
  );
}

export default ProductPage;