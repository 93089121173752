import './PageHeader.css';
import React, { useEffect, useState, useCallback, } from 'react';
import { useParams, } from 'react-router-dom';
import i18n from '../i18n';
import {
  Link,
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import request from '../request';

function PageHeader(props) {
  let params = useParams();

  let [title, setTitle] = useState('');
  let [category, setCategory] = useState(null);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    document.title = i18n.t('page_page_title');
    let unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      forceUpdate();
    });

    return () => {
      unlistenOnLanguageChange();
    };
  }, []);

  useEffect(() => {
    if ('productId' in params) {
      request.get(`api/product/${params.productId}`).then((response) => {
        setTitle([i18n.get_name(response.data.category), i18n.get_title(response.data)]);
        setCategory(response.data.category);
      });
    }

    if ('categoryId' in params) {
      request.get(`api/categories/${encodeURI(params.categoryId)}`).then((response) => {
        if (response.data) {
          setTitle([i18n.get_name(response.data), null]);
          setCategory(response.data);
        }
      });
    }

    if (!('productId' in params) && !('categoryId' in params)) {
      setTitle('')
    }
  }, [params.productId, params.categoryId])

  useEffect(() => {

  }, [params.productId, params.categoryId]);

  return (
    <div className="page-header">
      <div className="breadcrumb">
        <Link to="/">{i18n.t('home_page')}</Link>
        <FontAwesomeIcon
          icon={faChevronRight}
        />
        {
          props.page
            ? <span>{i18n.t(props.page + '_on_header')}</span>
            : ''
        }
        {
          title.length
            ? (
              <div>
                <span>
                  {
                    category
                      ? <Link to={`/categories/${category.id}`}>{title[0]}</Link>
                      : ''
                  }
                </span>
                {
                  title[1]
                    ? <React.Fragment>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                        />
                        <span>{title[1]}</span>
                      </React.Fragment>
                    : ''
                }
              </div>
            )
            : ''
        }
      </div>
    </div>
  );
}

export default PageHeader;