import './Slider.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import anime from 'animejs';

class Slider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      seeingLastItem: false,
    };

    this.onWindowResize = () => {
      this.positionItems();
    };

    this.elementRef = React.createRef();

    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    this.positionItems();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  positionItems(animate = false) {
    let items = this.elementRef.current.querySelector('.slider__items').children;
    let cursorX = 50;
    for (let i = this.state.index; i >= 0; i--) {
      if (animate) {
        anime({
          targets: items[i],
          left: cursorX + 'px',
          easing: 'easeOutCubic',
          duration: 700,
        });
      } else {
        items[i].style.left = cursorX + 'px';
      }
      cursorX -= items[i].offsetWidth + 50;
    }
    cursorX = items[this.state.index].offsetWidth + 50 + 50;
    for (let i = this.state.index + 1; i < items.length; i++) {
      if (animate) {
        anime({
          targets: items[i],
          left: cursorX + 'px',
          easing: 'easeOutCubic',
          duration: 700,
        });
      } else {
        items[i].style.left = cursorX + 'px';
      }
      cursorX += items[i].offsetWidth + 50;
    }
  }

  previous() {
    let index = this.state.index - 1;
    if (index < 0) {
      index = 0;
    }
    this.setState({
      ...this.state,
      index,
      seeingLastItem: false,
    });
    setTimeout(() => {
      this.positionItems(true);
    });
  }

  next() {
    let items = this.elementRef.current.querySelector('.slider__items').children;
    let index = this.state.index + 1;
    if (index >= items.length) {
      index = items.length - 1;
    }
    this.setState({
      ...this.state,
      index,
      seeingLastItem: index === items.length - 1,
    });
    setTimeout(() => {
      this.positionItems(true);
    });
  }

  render() {
    return (
      <div
        className="slider"
        ref={this.elementRef}
      >
        <div className="slider__items">
          {this.props.children}
        </div>
        <FontAwesomeIcon
          icon={faAngleLeft}
          className={`slider__btn-navigation slider__btn-previous ${this.state.index === 0 ? 'hidden' : ''}`}
          onClick={this.previous}
        />
        <FontAwesomeIcon
          icon={faAngleRight}
          className={`slider__btn-navigation slider__btn-next ${this.state.seeingLastItem ? 'hidden' : ''}`}
          onClick={this.next}
        />
      </div>
    );
  }
}

export default Slider;