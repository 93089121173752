import './ProductAvailability.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import i18n from '../i18n';

class ProductAvailability extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="ProductAvailability">
        <div className="mt-1 availability-area" style={{fontSize: '16px', color: '#FFF', backgroundColor: `#000`, borderRadius: '5px', padding: '8px', maxWidth: '168px', display: 'inline-block'}}>
          <FontAwesomeIcon icon={faPaperPlane} />
          &nbsp;&nbsp;&nbsp;
          {i18n.get_name(this.props.data)}
        </div>
      </div>
    );
  }
}

export default ProductAvailability;