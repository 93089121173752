const storage = {
  categories: [],
  company: {},
  me: {},
  onChangeFns: [],

  onChange(fn) {
    this.onChangeFns.push(fn);
  },
  
  set(propertyKey, data) {
    this[propertyKey] = data; 
    this.onChangeFns.forEach((fn) => {
      fn(propertyKey);
    });
  },
};
window.storage = storage;

export default storage;