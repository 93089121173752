import './CheckoutPayment.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faShoppingBasket, faSquare, faCheckSquare, faCircle, faCheckCircle, faBoxOpen, faExclamation, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import basket from '../basket';
import Select from 'react-select';
import storage from '../storage';

class CheckoutPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order_id: props.match.params.id,
      price: 0,
      ready: false
    };

    
  }

  componentDidMount() {
    document.title = i18n.t('checkout_page_title');
    

    i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
    request.get(`api/payment-order/${this.state.order_id}`).then((response) => {
      
      this.setState({
        ...this.state,
        price: response.data.total_cost,
        ready: true
      });

      (function(d){
        var s = d.createElement("script");
        
        s.setAttribute("src", "https://www.simplify.com/commerce/simplify.pay.js");
        (d.body || d.head).appendChild(s);
      })(document)
    })
    
  }

  
  render() {
    

    return (
      <div className={`checkout-page`}>
        {this.state.ready && 
        <iframe style={{width: '100%',
            minHeight: '900px',
            border: 'none'}} name="my-hosted-form"
                                    data-sc-key="lvpb_NmQ5YzAwODYtMzc1YS00MzA3LTljNGItMTdjMjRlNWY1Mjk0"
                                    data-name="funky-office Store"
                                    data-description="Προϊόντα γραφείου"
                                    data-reference={this.state.order_id}
                      data-amount={parseFloat(this.state.price * 100).toFixed(0)}
                      data-operation="create.payment"
                      data-redirect-url="https://funky-office.gr/api/complete-order"
                      data-color="#000"
                      data-receipt="true"
                      >
        </iframe>
        }
      </div>
    );
  }
}

export default CheckoutPayment;