import './BasketPage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBasket, faQrcode } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import NumberPicker from '../number-picker/NumberPicker';
import request from '../request';
import axios from 'axios';
import basket from '../basket';

class BasketPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      coupon: '',
      discountPerc: 0,
    };

    this.onCouponChange = this.onCouponChange.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);

    this.couponChangeTimeout = null;
  }

  componentDidMount() {
    document.title = i18n.t('basket_page_title');
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    basket.onChange(() => {
      this.setState({
        ...this.state,
        products: basket.products,
      });
      this.forceUpdate();
    });

    this.setState({
      ...this.state,
      products: basket.products,
    });
  }

  componentDidUpdate() {
    
    document.title = i18n.t('basket_page_title');
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  onCouponChange(e) {
    this.setState({
      ...this.state,
      coupon: e.target.value,
    });

    clearTimeout(this.couponChangeTimeout);
    this.couponChangeTimeout = setTimeout(() => {
      request.get(`api/check-coupon`).then((response) => {
        console.warn('Unhandled response: ', response);
        this.setState({
          ...this.state,
          discountPerc: 10,
        });
      });
    }, 350);
  }

  removeProduct(product) {
    basket.removeProduct(product.id, product.variant_id);
  }

  calculatePrice(product) {
    let price = 0;
    if(product.selectedVariant && product.selectedVariant.price > 0){
      price = product.selectedVariant.price;
    }else{
      price = product.price || product.sale;
    }
    if(price == 0 && product.variants.findIndex((i) => i.price > 0) > -1){
      price = product.variants.reduce(function(prev, curr) {
        return prev.price < curr.price ? prev : curr;
      }).price;
      return "Από " + parseFloat(price).toFixed(2);
    }

    return parseFloat(price).toFixed(2);
  }

  setProductQuantity(product, quantity) {
    basket.setQuantity(product, quantity);
  }

  render() {
    const grandTotal = this.state.products.map((product) => {
      return (this.calculatePrice(product)) * product.quantity;
    }).reduce((p, c) => {
      return p + c;
    }, 0);
    const discount = grandTotal * (this.state.discountPerc / 100);
    const taxes = grandTotal * 0.24;
    const subtotal = grandTotal - taxes;

    return (
      <div className={`basket-page ${this.state.products.length === 0 && localStorage.basket === '{}' ? 'd-row-center empty-height' : ''}`}>
        {
          this.state.products.length === 0 && localStorage.basket !== '{}'
            ? <div className="skeleton"></div>
            : ''
        }
        {
          this.state.products.length === 0 && localStorage.basket === '{}'
            ? <div className="d-column-center h-100">
                <FontAwesomeIcon icon={faShoppingBasket} size="3x" />
                <span className="mt-2">{i18n.t('empty_basket')}</span>
              </div>
            : ''
        }
        <table className={`${this.state.products.length === 0 ? 'hidden' : ''}`}>
          <thead>
            <tr>
              <th>{i18n.t('image')}</th>
              <th>{i18n.t('product_name')}</th>
              <th>{i18n.t('unit_price')}</th>
              <th>{i18n.t('quantity')}</th>
              <th>{i18n.t('subtotal')}</th>
              <th>{i18n.t('action')}</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.products.length === 0
                ? <tr><td colSpan="6">{i18n.t('empty_basket')}</td></tr>
                : <tr></tr>
            }
            {
              this.state.products.map((product, i) => {
                return (
                  <tr
                    key={i}
                  >
                    <td><img src={product.thumbnail} height="50" alt="" /></td>
                    <td className="name"><Link to={`/products/${product.id}`}>{product.name}</Link></td>
                    <td>{this.calculatePrice(product)}&nbsp;€</td>
                    <td>
                      <div className="d-row-center">
                        <NumberPicker
                          value={product.quantity}
                          onChange={(value) => this.setProductQuantity(product, value)}
                        />
                      </div>
                    </td>
                    <td>{parseFloat((this.calculatePrice(product)) * product.quantity).toFixed(2)}&nbsp;€</td>
                    <td>
                      <div className="d-row-center">
                        <button
                          type="button"
                          className="btn btn-sm btn-outline"
                          onClick={() => this.removeProduct(product)}
                        >
                          {i18n.t('remove_product_from_basket')}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <div className={`total mt-2 ${this.state.products.length === 0 ? 'hidden' : ''}`}>
          <div className="header">
            {i18n.t('order_total')}
          </div>
          <div className="body">
            <div>
              <span>{i18n.t('subtotal')}</span>
              <span>{subtotal.toFixed(2)}&nbsp;€</span>
            </div>
            {
              discount > 0 
              &&
              <div>
                <span>{i18n.t('discount')}</span>
                <span>{discount.toFixed(2)}&nbsp;€</span>
              </div>
            }
            
            <div>
              <span>{i18n.t('taxes')}</span>
              <span>{taxes.toFixed(2)}&nbsp;€</span>
            </div>
          </div>
          <div className="coupon">
            <FontAwesomeIcon icon={faQrcode} />
            <label htmlFor="coupon">Coupon</label>
            <input
              id="coupon"
              type="text"
              placeholder={i18n.t('input_coupon')}
              value={this.state.coupon}
              onChange={this.onCouponChange}
            />
          </div>
          <div className="footer">
            <span>{i18n.t('grand_total')}</span>
            <span>{(discount ? grandTotal - discount : grandTotal).toFixed(2)}&nbsp;€</span>
          </div>
          <div className="controls">
            <Link
              to="/"
              className="btn btn-outline mb-2 ml-2"
            >
              {i18n.t('continue_shopping')}
            </Link>
            <Link
              to="/checkout"
              className="btn mb-2 mr-2"
            >
              {i18n.t('continue_to_checkout')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default BasketPage;