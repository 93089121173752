import './CompleteOrder.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faShoppingBasket, faSquare, faCheckSquare, faCircle, faCheckCircle, faBoxOpen, faExclamation, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import basket from '../basket';
import Select from 'react-select';
import storage from '../storage';
import history from '../history';

class CompleteOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order_id: props.match.params.id,
      success: 0
    };

    
  }

  componentDidMount() {
    document.title = i18n.t('checkout_page_title');
    

    i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
    request.get(`api/payment-order-completed/${this.state.order_id}`).then((response) => {
      if(response.data.status == 1){
        this.setState({
          ...this.state,
          success: response.data.status
        });
        basket.clear()
      }
      else {
        history.push('/');
      }

      
    })
    
  }

  
  render() {
    

    return (
      <div className={`checkout-page`}>
        <div className={`order-sent-message`}>
        <FontAwesomeIcon icon={faCheckDouble} />
        <span
          style={{textShadow: '4px 0 0 #FAFAFA, -4px 0 0 #FAFAFA, 0 4px 0 #FAFAFA, 0 -4px 0 #FAFAFA, 1px 1px #FAFAFA, -1px -1px 0 #FAFAFA, 1px -1px 0 #FAFAFA, -1px 1px 0 #FAFAFA'}}
        >
          {i18n.t('order_sent_message')}
        </span>
        <div className="d-flex flex-direction-column align-items-center fs-xxl mt-5">
          <span className="position-absolute fs-xs">{i18n.t('order_number')}</span>
          <span>#{this.state.order_id}</span>
        </div>
        </div>
      </div>
    );
  }
}

export default CompleteOrder;