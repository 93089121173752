import './Terms.css';
import React from 'react';
import i18n from '../i18n';
import request from '../request';

class Terms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            terms: []
        };
    }
    initialize = () => {
        this.forceUpdate()
        document.getElementById("terms-wrapper").innerHTML = '';
        this.state.terms.map((term, i) => {
            let div = document.createElement("div");
            div.innerHTML = `<div class='content'>
                <h3 class='title'>${i18n.get_name(term)}</h3>
                <div class='term-content'>
                    <p id="terms-wrapper">
                        ${i18n.get_content(term)}
                    </p>
                </div>
            </div>`;
            
            document.getElementById("terms-wrapper").append(div);
        });
        
    }
    componentDidMount() {
        i18n.onLanguageChange(this.initialize)
        request.get(`api/terms-of-use`).then((response) => {
            this.setState({
                ...this.state,
                terms: response.data
                
            });
            response.data.map((term, i) => {
                let div = document.createElement("div");
                div.innerHTML = `<div class='content content-${term.id}'>
                    <h3 class='title'>${i18n.get_name(term)}</h3>
                    <div class='term-content'>
                        <p id="terms-wrapper">
                            ${i18n.get_content(term)}
                        </p>
                    </div>
                </div>`;
                document.getElementById("terms-wrapper").append(div);
                
                
            });
            if(this.props.match.params){
                const page = this.props.match.params.page
                if (page && page == 'terms_of_use') {
                    console.log(document.querySelector('.content-7'))
                    if(document.querySelector('.content-7'))
                        setTimeout(() => {
                            document.querySelector('.content-7').scrollIntoView()
                        }, 1000)
                }
            }
        });
    }

    componentWillUnmount() {
        i18n.onLanguageChangeUnmount(this.initialize)
    }

    render() {
        return (
            <div className='column container'>
                <h3 className='title top-title'>{i18n.t("terms_of_use_cap")}</h3>
                <div id="terms-wrapper"></div>
                
            </div>
        );
    }
}
export default Terms;