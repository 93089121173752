import './CategoriesPage.css';
import React from 'react';
import SkeletonCategoryItem from '../category-skeleton-item/SkeletonCategoryItem';
import CategoryItem from '../category-item/CategoryItem';
import ProductItem from '../product-item/ProductItem'; // 
import i18n from '../i18n';
import request from '../request';

class CategoriesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      products: [], //
    };
  }

  componentDidMount() {
    document.title = i18n.t('categories_page_title');
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    request.get(`api/categories`).then((response) => {
      let data = response.data;

      let getCategories = response.data.categories; //
      let getProducts = response.data.products; //

      // data = data.sort((a, b) => {
      //   if (parseInt(a.productsCount) > parseInt(b.productsCount)) {
      //     return -1;
      //   } else {
      //     return 1;
      //   }
      // });
      if(getCategories != undefined) {
        getCategories = getCategories.sort((a, b) => {
          if (parseInt(a.productsCount) > parseInt(b.productsCount)) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      else {
        getCategories = data.sort((a, b) => {
            if (parseInt(a.productsCount) > parseInt(b.productsCount)) {
              return -1;
            } else {
              return 1;
            }
          });
      }

      // this.setState({
      //   ...this.state,
      //   categories: data.map((category) => {
      //     return {
      //       id: category.id,
      //       name: category.name,
      //       image: category.image,
      //       productCount: category.productsCount,
      //       parentCategoryId: parseInt(category.parent_id),
      //     };
      //   }),
      // });
      this.setState({
        ...this.state,
        categories: getCategories.map((category) => {
          return {
            id: category.id,
            // name: category.name,
            name: category.name,
            name_en: category.name_en,
            image: category.image,
            fallback_image: category.fallback_image,
            productCount: category.productsCount,
            parentCategoryId: parseInt(category.parent_id),
          };
        }),
      });

      if(getProducts != undefined) {
        this.setState({
          ...this.state,
          products: getProducts.map((product) => {
            return {
              id: product.id,
              code: product.code,
              // title: product.title,
              name: product.title,
              name_en: product.title_en,
              thumbnail: product.thumbnail,
            };
          }),
        });
      }

    });
  }

  componentDidUpdate() {
    document.title = i18n.t('categories_page_title');    
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  render() {
    return (
      <div className="categories-page">
        <div className="list">
          {
            this.state.categories.map((category, i) => {
              return (
                <CategoryItem
                  key={i}
                  data={category}
                />
              );
            })
          }
          {
            this.state.categories.length === 0 && Array.from(Array(12)).map((n, i) => {
              return (
                <SkeletonCategoryItem key={i} />
              );
            })
          }
        </div>
        
        <div className='products-list'>
          { //
            this.state.products.map((product, i) => {
              return (
                <ProductItem 
                  key={i}
                  data={product}
                />
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default CategoriesPage;