import request from './request';

class Basket {
  constructor() {
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      localstorageProducts = [];
    }
    let promises = [];
    console.log(localstorageProducts);
    localstorageProducts.forEach( (product) => {
      promises.push(request.get(`api/product/${product.id}`).then((response) => {
        let variant_id = 0;
        if(product.selectedVariant){
          variant_id = product.selectedVariant.id;
        }
        // let selectedVariant = false;
        // let title = response.data.title;
        // if(variant_id > 0){
        //   selectedVariant = response.data.variants[response.data.variants.findIndex( (v) => v.id === variant_id)];
        //   if (selectedVariant) {
        //     title += " (";
        //     selectedVariant.attributes.forEach( (a) => {
        //       title += `${a.attribute_category}: ${a.value} `;
        //     });
        //     title += " )";
        //   }
        // }
        let selectedVariant = false;
        let title = '#' + response.data.code + ' ' + response.data.title;
        let title_en = '#' + response.data.code + ' ' + response.data.title_en;
        if(variant_id > 0){
          if(response.data.variants){
            selectedVariant = response.data.variants[response.data.variants.findIndex( (v) => v.id === variant_id)];
            if (typeof selectedVariant !== 'undefined' && selectedVariant.attributes) {
              title += " (";
              title_en += " (";
              selectedVariant.attributes.forEach( (a) => {
                title += `${a.attribute_category}: ${a.value} `;
                title_en += `${a.attribute_category_en}: ${a.value_en} `;
              });
              title += " )";
              title_en += " )";
            }
            
          }
        }
        
        this.products.push({
          ...response.data,
          name: title,
          name_en: title_en,
          thumbnail: `https://manager.funky-office.gr/images/products/${response.data.thumbnail}`,
          quantity: product.quantity,
          selectedVariant: selectedVariant,
          variant_id: variant_id,
        });
      }));
    });
    Promise.all(promises).then(() => {
      this.onChangeFns.forEach((fn) => fn());
    });
  }

  products = [];
  onAddProductFns = [];
  onRemoveProductFns = [];
  onChangeFns = [];

  addProduct(product, quantity) {
    console.log("product is", product)
    let variant_id = 0;
    if (product.selectedVariant) {
      variant_id = product.selectedVariant.id;
    }
    const productIndex = this.products.findIndex((p) => p.id === product.id && p.variant_id == variant_id);
    if (productIndex > -1) {
      this.products[productIndex].quantity += quantity;
    } else {
      this.products.push({
        ...product,
        thumbnail: `https://manager.funky-office.gr/images/products/${product.thumbnail}`,
        variant_id: variant_id,
        quantity: quantity,
      });
    }
    
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      localstorageProducts = [];
    }
    let localstorageIndex = localstorageProducts.findIndex((p) => p.id === product.id && p.variant_id == variant_id);
    if (localstorageIndex > -1) {
      localstorageProducts[localstorageIndex].quantity += quantity;
    }else{
      localstorageProducts.push({
        id: product.id,
        variant_id: variant_id,
        quantity: quantity,
      });
    }

    localstorageIndex = localstorageProducts.findIndex((p) => p.id === product.id && p.variant_id == variant_id);

    localstorageProducts[localstorageIndex].selectedVariant = product.selectedVariant;
    localStorage.setItem('basket', JSON.stringify(localstorageProducts));

    this.onAddProductFns.forEach((fn) => {
      fn(product);
    });
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }

  setQuantity(product, quantity) {

    let variant_id = 0;
    if(product.selectedVariant){
      variant_id = product.selectedVariant.id;
    }
    const productIndex = this.products.findIndex((p) => p.id === product.id && p.variant_id == variant_id);
    if (productIndex > -1) {
      this.products[productIndex].quantity = quantity;
    }
    
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      return;
      //localstorageProducts = {};
    }
    const localstorageIndex = localstorageProducts.findIndex((p) => p.id === product.id && p.variant_id === variant_id);
    if (localstorageIndex > -1)
    localstorageProducts[localstorageIndex].quantity = quantity;
    localStorage.setItem('basket', JSON.stringify(localstorageProducts));

    this.onAddProductFns.forEach((fn) => {
      fn(product);
    });
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }

  removeProduct(productId, variant_id) {
    this.products.splice(this.products.findIndex((p) => p.id === productId && p.variant_id === variant_id), 1);
    
    let localstorageProducts = localStorage.getItem('basket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      return;
      //localstorageProducts = {};
    }
    const localstorageIndex = localstorageProducts.findIndex((p) => p.id === productId && p.variant_id === variant_id);
    localstorageProducts.splice(localstorageIndex, 1);
    localStorage.setItem('basket', JSON.stringify(localstorageProducts));

    this.onRemoveProductFns.forEach((fn) => {
      fn(productId, variant_id);
    });
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }

  onAddProduct(fn) {
    this.onAddProductFns.push(fn);
  }

  onRemoveProduct(fn) {
    this.onRemoveProductsFns.push(fn);
  }

  onChange(fn) {
    this.onChangeFns.push(fn);
  }

  clear() {
    this.products = [];
    localStorage.setItem('basket', '[]');
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }
}

const basket = new Basket();
window.basket = basket;

export default basket;