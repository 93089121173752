import './SearchWindow.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import request from '../request';
import history from '../history';
import storage from '../storage';

class SearchWindow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      suggestions: [],
    };

    this.searchTimeout = null;

    this.elementRef = React.createRef();

    this.search = this.search.bind(this);
    this.onQueryKeyDown = this.onQueryKeyDown.bind(this);
  }

  componentDidMount() {
    this.unlistenRouteChange = history.listen(() => {
      this.props.onClose();
    });
  }

  componentWillUnmount() {
    this.unlistenRouteChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.open === false && this.props.open === true) {
      this.elementRef.current.querySelector('input[type="text"]').focus();
    }
  }

  search(e) {
    const query = e.target.value;

    this.setState({
      ...this.state,
      query: query,
    });

    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      request.get(`api/search/products?q=${query}&lite=1`).then((response) => {
        this.setState({
          ...this.state,
          suggestions: response.data.data.products,
        });
      });
    }, 350);
  }

  onQueryKeyDown(e) {
    if (e.code === 'Enter') {
      history.push(`/search/products/${this.state.query}`);
      this.props.onClose();
    }
  }

  render() {
    return (
      <div
        className={`search-window ${this.props.open ? 'open' : 'hidden'}`}
        ref={this.elementRef}
      >
        <h1 className="logo">{storage.company.eshop_name}</h1>
        <div
          className="btn-close-times"
          onClick={this.props.onClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className={`search-bar ${this.state.query.trim().length > 0 && this.state.suggestions.length > 0 ? 'with-suggestions' : ''}`}>
          <label htmlFor="input-search">{i18n.t('search')}</label>
          <input
            id="input-search"
            type="text"
            value={this.state.query}
            onChange={this.search}
            onKeyDown={this.onQueryKeyDown}
            autoComplete="off"
          />
          <FontAwesomeIcon icon={faSearch} />
          <div className={`suggestions ${this.state.query.trim().length > 0 && this.state.suggestions.length > 0 ? 'visible' : ''}`}>
            <div className="list">
              {
                this.state.suggestions.map((product, i) =>
                  <Link
                    key={i}
                    className="item"
                    to={`/products/${product.id}`}
                  >
                    {product.title}
                  </Link>
                )
              }
            </div>
          </div>
        </div>
        <div className="controls">
          <div className="btn-search">
            <button
              type="button"
            >
              {i18n.t('search')}
            </button>
          </div>
          <div className="btn-close">
            <button
              type="button"
              onClick={this.props.onClose}
            >
              {i18n.t('close')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchWindow;