import axios from 'axios';

const baseURL = 'https://funky-office.gr/';
// const baseURL = 'http://localhost/funky-office/';

class Request {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: baseURL,
      withCredentials: true,
    });
  }

  createHttpRequest(method, url, data = null) {
    const methodToLowerCase = method.toLowerCase();
    if (methodToLowerCase in axios && typeof axios[methodToLowerCase] === 'function') {
      return this.axiosInstance.bind(window, { method, url, baseURL, data, });
    } else {
      return () => { new Promise((resolve) => resolve({ data })); };
    }
  } 

  get(url) {
    const httpRequest = this.createHttpRequest('GET', url);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
      // await new Promise((resolve) => {
      //   setTimeout(resolve, 1200);
      // });

      return httpResponse;
    }).catch((error, response) => {
      console.log(`%cHTTP GET Request error\r\n${baseURL + url}\r\n%c${error}`, 'background-color: #FFF; color: #FF0000; font-size: 18px; border: 2px solid #FF0000; padding: 8px;', 'background-color: #FFF; color: #333; font-size: 15px;');

      return error;
    });
  }

  post(url, data) {
    const httpRequest = this.createHttpRequest('POST', url, data);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
    // await new Promise((resolve) => {
    //   setTimeout(resolve, 1200);
    // });

    return httpResponse;
    });
  }


  patch(url, data) {
    const httpRequest = this.createHttpRequest('PATCH', url, data);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
    // await new Promise((resolve) => {
    //   setTimeout(resolve, 1200);
    // });

    return httpResponse;
    });
  }


  // patch alias
  update(url, data) {
    const httpRequest = this.createHttpRequest('PATCH', url, data);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
    // await new Promise((resolve) => {
    //   setTimeout(resolve, 1200);
    // });

    return httpResponse;
    });
  }


  delete(url, data) {
    const httpRequest = this.createHttpRequest('DELETE', url, data);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
    // await new Promise((resolve) => {
    //   setTimeout(resolve, 1200);
    // });

    return httpResponse;
    });
  }

}

const request = new Request();
window.request = request;

export default request;