import { useState, useEffect, } from "react";
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import history from './history';
import ajaxStorage from "./ajax-storage";
import Header from './header/Header';
import Footer from './footer/Footer';
import ContactPage from './contact-page/ContactPage';
import HomePage from './home-page/HomePage';
import BlogPage from "./blog-page/BlogPage";
import CategoriesPage from "./categories-page/CategoriesPage";
import PageHeader from "./page-header/PageHeader";
import Basket from './basket/Basket';
import Terms from './terms/Terms';
import ProductPage from './product-page/ProductPage';
import Signin from './signin-page/SigninPage';
import Signup from './signup-page/SignupPage';
import ProductsPage from "./products-page/ProductsPage";
import BasketPage from "./basket-page/BasketPage";
import CheckoutPage from "./checkout-page/CheckoutPage";
import ProductsMiscPage from "./products-misc-page/ProductsMiscPage";
import AccountPage from "./account-page/AccountPage";
import request from "./request";
import storage from "./storage";
import i18n from "./i18n";
import CheckoutPayment from "./checkout-payment/CheckoutPayment";
import CompleteOrder from "./complete-order/CompleteOrder";

const onOpenBasketFns = [];

function App(props) {
  const [user, setUser] = useState({
    authenticated: false,
  });

  const [loading, setLoading] = useState(true);

  const [headerType, setHeaderType] = useState(localStorage['header-fixed'] === '1' ? 'sticky' : 'normal');

  function attachListenerOnOpenBasket(fn) {
    onOpenBasketFns.push(fn);
  }

  function onOpenBasket() {
    onOpenBasketFns.forEach((fn) => {
      fn();
    });
  }

  function headerTypeChanged(type) {
    setHeaderType(type);
  }

  useEffect(() => {
    request.get(`api/company`).then((response) => {
      storage.set('company', response.data);
      setLoading(false);
    });

    checkSignInStatus();

    window.addEventListener("resize", onResizeFunction);
    window.addEventListener("load", onResizeFunction);
    function onResizeFunction (e){
      var header = document.querySelector('.header');
      if(typeof header !== "undefined"){
        var clientHeight = header.clientHeight;
        document.querySelector('.App.header-fixed').style.paddingTop = clientHeight + "px";
      }
      

    }

    // document.querySelectorAll(".language-select .options .option").forEach(a => {
    //   a.addEventListener("click", function(e){
    //     console.log(e.target)
    //     UserWay.changeWidgetLanguage(i18n.language);
    //     // let lang = e.target.querySelector('span').innerHTML == 'English' ? 'en' : 'el';
    //     // console.log(lang)
    //   })
    // })

    
  }, []);

  useEffect(() => {
    if (!loading) {
      (document.querySelector('#loading') || { remove() {} }).remove();
    }
  }, [loading]);

  function onLogout() {
    setUser({
      authenticated: false,
    });
    history.push('/signin');
  }

  function checkSignInStatus() {
    request.get('api/status').then((response) => {
      console.log(response)
      if (response.data.login) {
        setUser({
          authenticated: true,
        });
        storage.set('me', response.data.data);
      }
    });
  }

  function onLogin() {
    setUser({
      authenticated: true,
    });

    checkSignInStatus();
  }

  useEffect(() => {
    (function(d){
      var s = d.createElement("script");
      /* uncomment the following line to override default position*/
      s.setAttribute("data-position", 3);
      /* uncomment the following line to override default size (values: small, large)*/
      s.setAttribute("data-size", "large");
      /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.)*/
      //s.setAttribute("data-language", 'el');
      /* uncomment the following line to override color set via widget (e.g., #053f67)*/
      /* s.setAttribute("data-color", "#2d68ff");*/
      /* uncomment the following line to override type set via widget (1=person, 2=chair, 3=eye, 4=text)*/
      /* s.setAttribute("data-type", "1");*/
      /* s.setAttribute("data-statement_text:", "Our Accessibility Statement");*/
      /* s.setAttribute("data-statement_url", "http://www.example.com/accessibility";*/
      /* uncomment the following line to override support on mobile devices*/
      /* s.setAttribute("data-mobile", true);*/
      /* uncomment the following line to set custom trigger action for accessibility menu*/
      /* s.setAttribute("data-trigger", "triggerId")*/
      s.setAttribute("data-account", "xeDC7d2QkJ");
      s.setAttribute("src", "https://cdn.userway.org/widget.js");
      (d.body || d.head).appendChild(s);
      document.addEventListener("userway:init_completed", function(event) {
          var instance = event.detail.userWayInstance;
          window.userWay = instance
      });
    })(document)

    i18n.onLanguageChange(() => {
      let lang = 'el'
      //console.log(i18n.language)
      if(i18n.language != 'el'){
        lang = 'en-US'
      }
      window.userWay.changeWidgetLanguage(lang)
    })
  }, [])

  

  return (
    <div className={`App header-fixed`} style={{visibility: loading ? 'hidden' : 'visible'}}>
      <Router
        history={history}
      >
        <Header
          onOpenBasket={onOpenBasket}
          onHeaderTypeChange={headerTypeChanged}
          user={user}
          onLogout={onLogout}
        ></Header>
        <Switch>
          <Route exact path="/">
            <HomePage
            />
          </Route>
          <Route path="/products/:productId">
            <PageHeader
              
            />
            <ProductPage
            />
          </Route>
          <Route path="/products">
            <PageHeader
              page="products"
            />
            <ProductsMiscPage

            />
          </Route>
          <Route path="/categories/:categoryId">
            <PageHeader
              
            />
            <ProductsPage
            />
          </Route>
          <Route path="/categories/:categoryId/:page">
            <PageHeader
              
            />
            <ProductsPage
            
            />
          </Route>
          <Route path="/search/products/:query">
            <ProductsPage
            
            />
          </Route>
          <Route path="/contact">
            <PageHeader
              page={'contact'}
            />
            <ContactPage />
          </Route>
          <Route path="/blog">
            <PageHeader
              page={'blog'}
            />
            <BlogPage />
          </Route>
          <Route path="/categories">
            <PageHeader
              page={'categories'}
            />
            <CategoriesPage />
          </Route>
          <Route path="/checkout">
            <PageHeader
              page={'checkout'}
            />
            <CheckoutPage
            />
          </Route>
          {/* <Route path="/checkout-payment/:id">
            <CheckoutPayment
            />
          </Route> */}
          <Route path='/checkout-payment/:id' render={(props) => <CheckoutPayment {...props}/>}/>
          <Route path='/complete_order/:id' render={(props) => <CompleteOrder {...props}/>}/>
          <Route path="/signin">
            <PageHeader
              page={'signin'}
            />
            <Signin
              onLogin={onLogin}
            />
          </Route>
          <Route path="/signup">
            <PageHeader
              page={'signup'}
            />
            <Signup 
              onLogin={onLogin}
            />
          </Route>
          <Route path="/basket">
            <PageHeader
              page={'basket'}
            />
            <BasketPage
            />
          </Route>
          <Route path="/terms/:page" render={(props) => <Terms 
              page={'terms'}
              {...props}
            />}>
            
          </Route>
          <Route path="/terms">
            <Terms 
              page={'terms'}
            />
          </Route>
          <Route path="/account">        
            <AccountPage />
          </Route>
        </Switch>
        <Footer></Footer>
        <Basket
          attachListenerOnOpen={attachListenerOnOpenBasket}
        ></Basket>
      </Router>
    </div>
  );
}

export default App;
